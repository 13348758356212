import React, { useCallback } from "react";
import axios from 'axios';
import PropTypes from 'prop-types';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { getCountingList, saveCountingList } from '../../../../services/countService';
import { loadPdfReportInfo } from '../../../../services/inventoryService';
import { systemDecimalSeparator, systemGroupSeparator } from '../../../../utils/localization';
import { useLoading } from "../../../Shared/LoadingContext";
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import { wizardEvents } from "../Wizard";
import { Card, CardBody, CardHeader } from 'reactstrap';
import { on, off } from "../../../../actions/events";
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import ConfirmDialog from "../../../Shared/ConfirmDialog";
import { useIntl, FormattedMessage } from "react-intl";
import { UserContext } from '../../../UserContext';
import GridFooter from "../../../Shared/GridFooter";
import { NumericCellWithCustomSeparators } from "../../../Shared/NumericCellWithCustomSeparators";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCompressAlt,
    faExpandAlt
} from '@fortawesome/pro-light-svg-icons'
import { getColumnValueFromFeedbackItem } from "./countingutils";

const MINIMUM_COLUMN_WIDTH = 50;
const MULTIPLIER = 15;

const RowRender = (properties) => {
    const { row, props } = { ...properties };
    const additionalClassName = props.dataItem.ActualQuantity === null ? " countedItemDiff" : "";
    const newClassName = row.props.className + additionalClassName;

    const trProps = {
        className: newClassName,
    };

    return React.cloneElement(
        row,
        { ...row.props, ...trProps },
        row.props.children
    );
};


const EnterCountingTable = ({ onCountingFinished, handleError }) => {
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'EnterCountingTable';
    const cancelTokenSource = axios.CancelToken.source();
    const wizzardContext = React.useContext(WizzardContext);
    const userContext = React.useContext(UserContext);
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const intl = useIntl();
    const [confirmContinueDialogVisible, setConfirmContinueDialogVisible] = React.useState(false);
    const [extended, setExtended] = React.useState(false);
    const defaultInterfaceConfiguration = wizzardContext.wizzardGlobal.InterfaceConfiguration;

    const [columns, setColumns] = React.useState([{}]);
    const [decimalSeparator, setDecimalSeparator] = React.useState(',');
    const [groupSeparator, setGroupSeparator] = React.useState(' ');

    const [showDifferencesConfirmation, setShowDifferencesConfirmation] = React.useState(false);
    const [differencesReportFile, setDifferencesReportFile] = React.useState();

    const [itemNoWithFocus, setItemNoWithFocus] = React.useState(null);

    const getDecimalSeparator = (decSepVal) => {
        let decSep = systemDecimalSeparator;

        switch (decSepVal) {
            case window.enumDecimalSeparator.Point:
                decSep = '.';
                break;
            case window.enumDecimalSeparator.Comma:
                decSep = ',';
                break;
            case window.enumDecimalSeparator.None:
                decSep = '';
                break;
            default:
                break;
        }

        return decSep;
    }

    const getGroupSeparator = (groupSepVal) => {
        let groupSep = systemGroupSeparator;
        switch (groupSepVal) {
            case window.enumThousandSeparator.None:
                groupSep = "";
                break;
            case window.enumThousandSeparator.Comma:
                groupSep = ',';
                break;
            case window.enumThousandSeparator.Dot:
                groupSep = '.';
                break;
            case window.enumThousandSeparator.Space:
                groupSep = ' ';
                break;
            case window.enumThousandSeparator.Apostrophe:
                groupSep = "'";
                break;
            default:
                break;
        }
        return groupSep;
    }

    const loadNumberSeparators = () => {
        setDecimalSeparator(getDecimalSeparator(defaultInterfaceConfiguration?.importCountQuantities?.decimalSeparator));
        setGroupSeparator(getGroupSeparator(defaultInterfaceConfiguration?.importCountQuantities?.thousandSeparator));
    }

    const itemChange = (e) => {
        let field = e.field || '';
        if (field === 'ActualQuantity' && e.value < 0) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "CountingList.NegativeValuesAreNotAccepted" })
            });
            return;
        }

        if ((wizzardContext.wizzardGlobal.Counting?.CountData?.length ?? 0) > 0) {
            let item = wizzardContext.wizzardGlobal.Counting.CountData.filter((x) => x.ItemNo === e.dataItem.ItemNo)[0];
            if (item) {
                item[e.field || ""] = e.value;
                item.ActualQuantityHasChanged = true;
            }

            let newEditedPageData = wizzardContext.wizzardGlobal.Counting.CountData.map((x) => {
                if (x.ItemNo === e.dataItem.ItemNo) {
                    return { ...item };
                }
                return x;
            });
            wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetCountData, payload: { CountData: newEditedPageData } });
        }
        else {
            console.error('editedPageData is empty, grid editor changes cannot be applied!');
        }
    };

    React.useEffect(() => {
        loadNumberSeparators();

        let page = wizzardContext.wizzardGlobal.Import.FirstMissingFeedbackPageNr ? wizzardContext.wizzardGlobal.Import.FirstMissingFeedbackPageNr : 1;
        loadData(page - 1, page);

        return () => { cancelTokenSource.cancel(); };
    }, []);

    const quantityChangesExistOnCurrentPage = (requestForSave) => {
        return ((requestForSave?.FeedbackLines?.filter(feedback => feedback.ActualQuantityHasChanged)?.length ?? 0) > 0);
    }

    const loadCountingPage = (pageNo) => {
        wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetPage, payload: { Page: pageNo } });
        loadData(wizzardContext.wizzardGlobal.Counting.Page, pageNo);
    }

    const doSaveCountingList = (nextPage) => {
        setLoading(true);
        let request = getRequestForSave();
        if (quantityChangesExistOnCurrentPage(request)) {
            setDifferencesReportFile(undefined);
            saveCountingList(
                request,
                () => {
                    if (nextPage) {
                        loadCountingPage(nextPage);
                    }
                    else if (!userContext.userGlobal.userInfo.isEditor) {
                        setLoading(false);
                        onCountingFinished?.(true);
                    }
                },
                doErrorHandling,
                selectedPopulation ? selectedPopulation.populationId : null,
                cancelTokenSource.token
            );
        }
        else if (nextPage) {
            loadCountingPage(nextPage);
        }
        else if (!userContext.userGlobal.userInfo.isEditor) {
            onCountingFinished?.(true);
        }
    }

    const createDifferencesList = () => {
        loadPdfReportInfo(
            selectedPopulation ? selectedPopulation.populationId : null,
            window.enumReportType.DifferencesList,
            (file, response) => {
                setDifferencesReportFile(file);
                setLoading(false);
            },
            handleError
        );
    };

    const saveCountingListAndCreateDifferencesList = React.useCallback(() => {
        setLoading(true);
        let request = getRequestForSave();
        if (differencesReportFile && !quantityChangesExistOnCurrentPage(request)) {
            setLoading(false);
            console.log('No quantity changes detected and differences list was already created!');
            setShowDifferencesConfirmation(true);
            return;
        }

        saveCountingList(
            request,
            () => {
                createDifferencesList();
                wizzardContext.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetCountData, payload: {
                        CountData: wizzardContext.wizzardGlobal.Counting.CountData.map(item => { return { ...item, ActualQuantityHasChanged: false }; })
                    }
                });
            },
            doErrorHandling,
            selectedPopulation ? selectedPopulation.populationId : null,
            cancelTokenSource.token
        );
    }, [differencesReportFile]);

    const doErrorHandling = (errorMessage, showNotif = true) => {
        setLoading(false);

        if (handleError) {
            handleError(errorMessage, showNotif, pageId);
        }
        else if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const handleConfirmContinueDialogClose = (result) => {
        setConfirmContinueDialogVisible(false);
        if (result) {
            doSaveCountingList();
        }
    }

    const saveInternalCounting = (_event) => {
        setConfirmContinueDialogVisible(true);
    }

    const openReportOnNewTab = (file) => {
        if (file) {
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
        }
    };

    const differencesButtonClicked = useCallback((_event) => {
        saveCountingListAndCreateDifferencesList();
    }, [saveCountingListAndCreateDifferencesList]);

    React.useEffect(() => {
        on(wizardEvents.saveInternalCounting, saveInternalCounting);
        on(wizardEvents.countingDifferencesButtonClicked, differencesButtonClicked);

        return () => {
            off(wizardEvents.saveInternalCounting, saveInternalCounting);
            off(wizardEvents.countingDifferencesButtonClicked, differencesButtonClicked);
        }
    }, [differencesButtonClicked]);

    const [headerLine, setHeaderLine] = React.useState(null);
    const loadData = (currentPage, nextPage) => {
        setLoading(true);
        getCountingList(
            currentPage ?? 0,
            nextPage,
            result => {
                onCountingFinished?.(false);
                setLoading(false);
                let items = transformFeedbackLines(result.headerLine, result.feedbackLines)
                setHeaderLine(result.headerLine);
                setColumns(getColumns(result.headerLine));
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetCountData, payload: { CountData: items } });
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetHeader, payload: { Header: result.headerLine } });
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetTotalPages, payload: { TotalPages: result.totalNumberOfPages } });
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetPage, payload: { Page: result.pageNo } });

                setKeydown();
            },
            doErrorHandling,
            selectedPopulation ? selectedPopulation.populationId : null
        );
    };

    React.useEffect(() => {
        if (headerLine) {
            let cols = getColumns(headerLine);
            setColumns(cols);
        }
    }, [extended]);

    React.useEffect(() => {
        setKeydown();
    }, [columns, itemNoWithFocus]);

    React.useEffect(() => {
        if (wizzardContext.wizzardGlobal.Counting.CountData && wizzardContext.wizzardGlobal.Counting.Page >= 0) {
            setKeydown();
        }
    }, [wizzardContext.wizzardGlobal.Counting.CountData]);

    React.useEffect(() => {
        setShowDifferencesConfirmation(differencesReportFile);
    }, [differencesReportFile]);

    const handleDifferencesConfirmDialogClose = (result) => {
        setShowDifferencesConfirmation(false);
        if (result) {
            openReportOnNewTab(differencesReportFile);
        }
    }

    const handleDifferencesDownload = () => {
        setShowDifferencesConfirmation(false);
        if (differencesReportFile) {
            saveAs(differencesReportFile, differencesReportFile.name);
        }
    }

    const addKeydownHandlerForInputArray = (tempInputs) => {
        const keyDown = (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                for (let i = 0; i < tempInputs.length; i++) {
                    if (document.activeElement.id === tempInputs[i].input.id && i + 1 < tempInputs.length) {
                        tempInputs[i + 1].input.focus();
                        setItemNoWithFocus(tempInputs[i + 1].id);
                        break;
                    }
                }
            }
        }

        const attribute = "HasListener";
        tempInputs.forEach(input => {
            if (!input.input.hasAttribute(attribute)) {
                input.input.addEventListener("keydown", keyDown);
                input.input.setAttribute(attribute, "true");
            }
        });
    }

    const assignKeydownHandlersToInputs = () => {
        let tempInputs = [];
        let tds = document.querySelectorAll('.editable-imput');
        tds.forEach(item => {
            let input = item.querySelectorAll("span > input")[0];
            let span = item.querySelectorAll("span")[0];
            for (const className of span.classList) {
                if (className.startsWith("masked-text-")) {
                    tempInputs.push({
                        input: input,
                        id: className
                    });
                    if (itemNoWithFocus === className) {
                        input.focus();
                    };
                    break;
                }
            }
        })

        addKeydownHandlerForInputArray(tempInputs);
    }

    const setKeydown = () => {
        if (wizzardContext.wizzardGlobal.Counting.CountData) {
            assignKeydownHandlersToInputs();
        }
    }

    const getColumns = (header) => {
        let itemNoWidth = header.itemNo.columnWidth * MULTIPLIER;
        itemNoWidth = itemNoWidth < MINIMUM_COLUMN_WIDTH ? MINIMUM_COLUMN_WIDTH : itemNoWidth;
        const arrowWidth = 30;
        let actualQuantityWidth = header.actualQuantity.columnWidth * MULTIPLIER + arrowWidth;
        actualQuantityWidth = actualQuantityWidth < MINIMUM_COLUMN_WIDTH ? MINIMUM_COLUMN_WIDTH : actualQuantityWidth;

        let columns = [
            {
                field: "ItemNo",
                title: header.itemNo.columnName,
                editable: false,
                width: itemNoWidth,
                locked: true
            }];

        header.uniqueId.forEach(function (item, index, array) {

            if (item.columnWidth > 0) {
                let width = item.columnWidth * MULTIPLIER;
                width = width < MINIMUM_COLUMN_WIDTH ? MINIMUM_COLUMN_WIDTH : width;
                let field = 'Column' + index;
                columns.push({
                    field: field,
                    title: item.columnName,
                    editable: false,
                    width: width,
                    locked: false
                });
            }
        });

        columns.push({
            field: "ActualQuantity",
            title: header.actualQuantity.columnName,
            editable: true,
            width: actualQuantityWidth,
            locked: true,
            className: "editable-imput"
        });

        let gridRemaingWidth = document.getElementById('enterCountingGrid').offsetWidth - itemNoWidth - actualQuantityWidth;
        let currentWidth = columns.map((item) => item.width).reduce((a, b) => a + b, 0) - itemNoWidth - actualQuantityWidth;
        let multiplyFactor = gridRemaingWidth > currentWidth ? gridRemaingWidth / currentWidth : 1;
        if (multiplyFactor > 1) {
            columns.filter((item) => !item.locked).forEach(item => {
                item.width = item.width * multiplyFactor;
            });
        }
        return columns;
    }

    const transformFeedbackLines = (header, data) => {
        return data.map((item) => {
            const newItem = {
                ItemNo: item.itemNo,
                ActualQuantity: item.actualQuantity,
                inEdit: true,
                ActualQuantityHasChanged: false,
                NotCounted: item.actualQuantity === null
            }
            for (let i = 0; i < header.uniqueId.length; i++) {
                let key = 'Column' + i;
                newItem[key] = getColumnValueFromFeedbackItem(item, i, header.uniqueId[i].fId);
            }
            return newItem;
        });
    };

    const setPageHandler = (page, doSave = true) => {
        if (page > 0 && page <= wizzardContext.wizzardGlobal.Counting.TotalPages) {
            if (doSave) {
                doSaveCountingList(page);
            }
            else {
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetPage, payload: { Page: page } });
                loadData(wizzardContext.wizzardGlobal.Counting.Page, page);
            }
        }
    };

    const getRequestForSave = () => {

        let request =
        {
            PageNo: wizzardContext.wizzardGlobal.Counting.Page
        }

        request.FeedbackLines = wizzardContext.wizzardGlobal.Counting.CountData.map((item) => {
            const newItem = {
                ItemNo: item.ItemNo,
                ActualQuantity: item.ActualQuantity,
                ActualQuantityHasChanged: item.ActualQuantityHasChanged,
                UniqueId: []
            }

            for (const element of wizzardContext.wizzardGlobal.Counting.Header.uniqueId) {
                newItem.UniqueId.push(item[element.columnName]);
            }

            return newItem;
        });

        return request;
    }

    const executeExtend = (event) => {
        event.preventDefault();
        setExtended(!extended);
    };

    const handleRowRender = (row, props) => {
        return (
            <RowRender
                props={props}
                row={row}
            />
        );
    };

    return (
        <>
            <Card className={extended ? "resize-large" : ""}>
                <CardHeader>
                    <h3>
                        <FormattedMessage id='CountingList.Title' />
                        <div className="float-right">
                            <button className="btn btn-secondary" onClick={executeExtend}>
                                <FontAwesomeIcon icon={extended ? faCompressAlt : faExpandAlt} />
                            </button>
                        </div>
                    </h3>
                </CardHeader>
                <CardBody>
                    <div id="enterCountingGrid">
                        <Grid
                            reorderable={true}
                            editField="inEdit" onItemChange={itemChange}
                            sortable={true}

                            rowRender={handleRowRender}

                            data={wizzardContext.wizzardGlobal.Counting.CountData}
                            pageable={false}
                        >
                            {columns.map((column, index) => {
                                return (
                                    <Column
                                        field={column.field}
                                        title={column.title}
                                        key={index}
                                        width={column.width}
                                        editable={column.editable}
                                        className={"selectableCell " + (column.className ?? "")}
                                        cell={
                                            column.editable
                                                ? (props) => NumericCellWithCustomSeparators(props, { decimalSeparator: decimalSeparator, groupSeparator: groupSeparator }, { allowEmptyEditField: 'NotCounted', keyField: 'ItemNo' })
                                                : undefined
                                        }
                                        locked={column.locked}
                                    />
                                );
                            })}
                        </Grid>
                    </div>
                    <GridFooter
                        setPageHandler={setPageHandler}
                        currentPage={wizzardContext.wizzardGlobal.Counting.Page}
                        totalPages={wizzardContext.wizzardGlobal.Counting.TotalPages}
                    />
                </CardBody>
            </Card>
            <ConfirmDialog
                visible={confirmContinueDialogVisible}
                onClose={handleConfirmContinueDialogClose}
                title={<FormattedMessage id='Counting.ConfirmContinueDialog.Title' />}
                detail={<FormattedMessage id='Counting.ConfirmContinueDialog.Detail' />}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Wizard.Continue' />}
            />
            <ConfirmDialog
                visible={showDifferencesConfirmation}
                title={<FormattedMessage id='Counting.ShowDifferencesDialog.Title' />}
                detail={<FormattedMessage id='Counting.ShowDifferencesDialog.Details' />}
                negative={<FormattedMessage id='Control.Cancel' />}
                neutral={<FormattedMessage id='Wizard.Download' />}
                positive={<FormattedMessage id='Control.Open' />}
                onClose={handleDifferencesConfirmDialogClose}
                onNeutral={handleDifferencesDownload}
            />
        </>
    );
};

EnterCountingTable.propTypes = {
    onCountingFinished: PropTypes.func,
    handleError: PropTypes.func,
};

export default EnterCountingTable;
