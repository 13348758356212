import { get } from './serviceBase';

const baseUrl = '/api/v1.0/Common';

export function getQrCode(plainText, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('plainText', plainText);
    const url = `${baseUrl}/GetQrCode?` + params;
    get(url, successCallback, errorCallback);
}

export const addDownloadLinkToDocument = (file, mimeType, fileName) => {
    const url = window.URL.createObjectURL(
        new Blob([file], {
            type: mimeType
        })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        fileName
    );

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export function downloadFile(file, mimeType) {
    addDownloadLinkToDocument(file, mimeType, file.name);
}

export async function saveFile(fileHandle, file, mimeType) {
    const blob = new Blob([file], {
        type: mimeType
    });

    const writableStream = await fileHandle.createWritable();
    await writableStream.write(blob);
    await writableStream.close();
}

export async function getNewFileHandle(defaultFileName, csvFilterName, txtFilterName, allFilterName) {
    if (window.showSaveFilePicker) {
        const opts = {
            excludeAcceptAllOption: true,
            suggestedName: defaultFileName,
            types: [
                {
                    description: csvFilterName ?? "CSV (Comma delimited)",
                    accept: {
                        "text/csv": [".csv"]
                    },
                },
                {
                    description: txtFilterName ?? 'Text files',
                    accept: {
                        'text/plain': ['.txt'],
                    },
                },
                {
                    description: allFilterName ?? 'All Files',
                    accept: {
                        'text/csv': ['.txt', '.csv'],
                        // Add more MIME types and their corresponding extensions here
                    },
                }
            ],
        };

        return window.showSaveFilePicker(opts);
    }
    
    console.warn("File picker not supported in this browser.");
    return null;
}
