import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import License from '../License/License';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsd } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

function LicenseSelection({ subscriptionLabel }) {
    const [visible, setVisible] = useState(false);

    const toggleLicenseSelectorVisibility = () => {
        setVisible(!visible);
    };

    return (
        <div>
            <Button
                color="link"
                onClick={toggleLicenseSelectorVisibility}
            >
                <FontAwesomeIcon icon={faUsd} />
                {subscriptionLabel}
            </Button>
            {visible && (
                <License
                    onClose={toggleLicenseSelectorVisibility}
                />
            )}
        </div>
    );
}

LicenseSelection.propTypes = {
    subscriptionLabel: PropTypes.string.isRequired,
};

export default LicenseSelection
