import { get, getWithParams, post, postWithParams } from './serviceBase';

const baseUrl = '/api/v1.0/Support';
const baseUrlDelayedTask = '/api/v1.0/DelayedTask';

export function getSupportScripts(successCallback, errorCallback) {
    const url = `${baseUrl}/GetSupportScripts`;
    get(url, successCallback, errorCallback);
}

export function executeSupportScript(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/ExecuteSupportScript`;
    post(url, payload, successCallback, errorCallback);
}

export function executeGarbageCollector(successCallback, errorCallback) {
    const url = `${baseUrl}/ExecuteGarbageCollector`;
    post(url, null, successCallback, errorCallback);
}

export function getWarehouseLocationByPopulation(populationId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('populationId', populationId);
    const url = `${baseUrl}/GetWarehouseLocationByPopulation?` + params;
    get(url, successCallback, errorCallback);
}

export function getCustomersWithEnablingArticleGrouping(successCallback, errorCallback) {
    const url = `${baseUrl}/GetCustomersWithEnablingArticleGrouping`;
    get(url, successCallback, errorCallback);
}

export function saveCustomersWithEnablingArticleGrouping(customers, successCallback, errorCallback) {
    const url = `${baseUrl}/SaveCustomersWithEnablingArticleGrouping`;
    post(url, customers, successCallback, errorCallback);
}

export function getCustomersWithPopulations(successCallback, errorCallback) {
    const url = `${baseUrl}/GetCustomersWithPopulations`;
    get(url, successCallback, errorCallback);
}

export function deleteCustomer(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/DeleteCustomer?` + params;
    post(url, null, successCallback, errorCallback);
}

export function getFullNLS(successCallback, errorCallback) {
    const url = `${baseUrl}/GetFullNLS`;
    get(url, successCallback, errorCallback);
}

export function saveNLSEntry(nlsEntry, successCallback, errorCallback) {
    const url = `${baseUrl}/SaveNLSEntry`;
    post(url, nlsEntry, successCallback, errorCallback);
}

export function getNLSEntry(nlsEntryKey, successCallback, errorCallback) {
    const url = `${baseUrl}/GetNLSEntry`;
    getWithParams(url, nlsEntryKey, successCallback, errorCallback);
}

export function deleteNLSEntry(key, successCallback, errorCallback) {
    const url = `${baseUrl}/DeleteNLSEntryByKey`;
    postWithParams(url, {} , { key }, successCallback, errorCallback);
}

export function clearNLSEntry(key, successCallback, errorCallback) {
    const url = `${baseUrl}/ClearNLSEntryByKey`;
    postWithParams(url, {}, { key }, successCallback, errorCallback);
}

export function getAdjustElasticPoolsTaskTime(successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('taskId', 'AdjustElasticPools');
    const url = `${baseUrlDelayedTask}/GetScheduledTaskTime?` + params;
    get(url, successCallback, errorCallback);
}

export function adjustElasticPoolsAt(dateTime, successCallback, errorCallback) {
    const url = `${baseUrlDelayedTask}/ScheduleTask`;
    post(url, { taskId:'AdjustElasticPools', executionTime: dateTime}, successCallback, errorCallback);
}

export function cancelScheduledAdjustmentOfElasticPools(successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('taskId', 'AdjustElasticPools');
    const url = `${baseUrlDelayedTask}/CancelScheduledTask?` + params;
    post(url, null, successCallback, errorCallback);
}



