import React, { useState, useCallback } from "react";
import { Link } from 'react-router-dom';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { RadioButton } from "@progress/kendo-react-inputs";
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import { wizardEvents, wizardStep, getCurrentProgramLockStateAndDoActions } from "../Wizard";
import { on, off, trigger } from "../../../../actions/events";
import PreviewPDF from '../../PreviewPDF';
import { loadPdfReportInfo } from '../../../../services/inventoryService';
import { downloadFile, addDownloadLinkToDocument, saveFile, getNewFileHandle } from '../../../../services/commonService';
import { exportCountItemsToFile, createStaseqCountList, createStasamCountList, fetchDefaultCountListFileName } from "../../../../services/countService";
import { useNotification } from "../../../Shared/Notifications/NotificationProvider";
import { useLoading } from "../../../Shared/LoadingContext";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { NotificationTypes } from "../../../Shared/Notifications/Notification";
import SignalRNotificationHandler from "../../../Shared/SignalRNotificationHandler";
import ConfirmDialog from '../../../Shared/ConfirmDialog';
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from 'react-redux';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { WaitingForErp } from "../WaitingForErp";
import axios from 'axios';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPaperPlane,
    faMobile,
    faMapMarkerAlt
} from '@fortawesome/pro-light-svg-icons'

const SelectCountingOption = ({ goToImportCounting, handleError }) => {
    const pageId = 'pageSelectCountingOption';
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const wizzardContext = React.useContext(WizzardContext);
    const [selectedValue, setSelectedValue] = React.useState(wizzardContext.wizzardGlobal.Counting.CountingOption ?? wizzardContext.wizzardGlobal.Import.CountingOption ?? window.enumCountingOption.ContinueHere);
    const [showCountListGeneration, setShowCountListGeneration] = React.useState(wizzardContext.wizzardGlobal.Import.IsBackgroundTaskRunning);
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const { setLoading } = useLoading();
    const [selected, setSelected] = useState(0);
    const [isPageCountWarningVisible, setIsPageCountWarningVisible] = useState(false);
    const [expectedPageCount, setExpectedPageCount] = useState(undefined);
    const [fileCountlist, setFileCountlist] = useState(null);
    const [defaultExportFileName, setDefaultExportFileName] = useState();
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const isCustomerWithoutLicense = currentCustomer.activeLicense.isFreeTrial;
    const waitingForErp = wizzardContext.wizzardGlobal.Import.WaitingForErp;
    const intl = useIntl();

    const cancelTokenSource = axios.CancelToken.source();

    const handleChange = React.useCallback(
        (e) => {
            setSelectedValue(e.value);
        },
        [setSelectedValue]
    );

    const notifyError = useCallback((errorMessage) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: pageId,
        message: errorMessage
    }), [dispatchNotification]);

    const onError = useCallback((errorMessage, showNotif = true) => {
        setShowCountListGeneration(false);
        setLoading(false);

        if (handleError) {
            handleError(errorMessage, showNotif, pageId);
        }
        else if (showNotif) {
            notifyError(errorMessage);
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    }, [setLoading, handleError, notifyError, dispatchExceptionDialog]);

    const getCountListPdfAndDoAction = useCallback((action) => {
        setLoading(true);
        loadPdfReportInfo(
            selectedPopulation ? selectedPopulation.populationId : null,
            window.enumReportType.Countlist,
            (file, response) => {
                setFileCountlist(file);
                setLoading(false);
                if (!response.expectedPageCount || response.renderedPageCount === response.expectedPageCount) {
                    if (action) {
                        action(file);
                    }
                }
                else {
                    setExpectedPageCount(response.expectedPageCount);
                    setIsPageCountWarningVisible(true);
                }
            },
            onError
        );
    }, [selectedPopulation, setLoading, onError, setFileCountlist]);

    const loadReportPreviewOnSelectedTab = async (selectedTabIndex) => {
        if (selectedTabIndex === 1) {
            if (!fileCountlist) {
                getCountListPdfAndDoAction();
            }
        }
    }

    const handleSelect = async e => {
        setSelected(e.selected);
        if (!isCustomerWithoutLicense) {
            loadReportPreviewOnSelectedTab(e.selected);
        }
    };

    const downloadCountlistPdf = useCallback((pdf) => {
        setLoading(false);
        downloadFile(pdf, "application/pdf");

        goToImportCounting();
    }, [setLoading, goToImportCounting]);

    const manageCountlistDownload = useCallback(() => {
        if (!fileCountlist) {
            setLoading(true);
            getCountListPdfAndDoAction((pdf) => downloadCountlistPdf(pdf));
        }
        else {
            downloadCountlistPdf(fileCountlist);
        }
    }, [downloadCountlistPdf, fileCountlist, getCountListPdfAndDoAction, setLoading]);

    const selectExportFileAndExportCountList = useCallback(async (fileHandle) => {

        setLoading(true);

        exportCountItemsToFile(
            selectedPopulation ? selectedPopulation.populationId : null,
            async (result) => {
                if (result.data.fileContent != null) {
                    let uint8 = new Uint8Array(result.data.fileContent.length);
                    for (let i = 0; i < uint8.length; i++) {
                        uint8[i] = result.data.fileContent.charCodeAt(i);
                    }

                    if (fileHandle) {
                        await saveFile(fileHandle, uint8, "text/csv");

                        dispatchNotification({
                            type: NotificationTypes.success,
                            pageId: pageId,
                            message: <FormattedMessage id="Control.FileDownloaded" values={{ FileName: fileHandle.name }} />
                        });
                    }
                    else {
                        addDownloadLinkToDocument(uint8, "text/csv", result.data.fileName);
                    }

                    setLoading(false);
                }
                else {
                    notifyError(<FormattedMessage id="SelectCountingOption.EmptyCountList" />);
                }
                goToImportCounting();
            },
            onError
        );
    }, [dispatchNotification, onError, notifyError, goToImportCounting, selectedPopulation, setLoading]);

    const downloadButtonClicked = React.useCallback(async () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });

        if (wizzardContext.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.ContinueHere) {
            manageCountlistDownload();
        }
        else if (wizzardContext.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.ExternalEditing) {
            let fileHandle;
            try {
                fileHandle = await getNewFileHandle(
                    defaultExportFileName ?? 'countlist',
                    intl.formatMessage({ id: "fileType.csv" }),
                    intl.formatMessage({ id: "fileType.txt" }),
                    intl.formatMessage({ id: "fileType.all" })
                );
            } catch (error) {
                if (error.name === "AbortError") {
                    return;
                }
                onError(error);
            }

            await selectExportFileAndExportCountList(fileHandle);
        }
    }, [defaultExportFileName, dispatchNotification, intl, manageCountlistDownload, onError, selectExportFileAndExportCountList, wizzardContext.wizzardGlobal.Counting.CountingOption]);

    const showReportPageCountWarningIfNeeded = useCallback((reportPageCountInfo) => {
        if (reportPageCountInfo && reportPageCountInfo.renderedPageCount !== reportPageCountInfo.expectedPageCount) {
            setIsPageCountWarningVisible(true);
        }
    }, []);

    const generateCountList = useCallback(() => {
        if (wizzardContext.wizzardGlobal.Import.InventoryMethod === window.enumInventoryMethod.Staseq) {
            let inputRequest = wizzardContext.wizzardGlobal.StaseqCounting;
            if (inputRequest) {
                setShowCountListGeneration(true);
                createStaseqCountList(selectedPopulation?.populationId, inputRequest,
                    response => {
                        setShowCountListGeneration(false);
                        showReportPageCountWarningIfNeeded(response);
                    },
                    onError,
                    cancelTokenSource.token
                );
                wizzardContext.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetStaseqCounting, payload: null
                });
            }

        }
        else {
            let inputRequest = wizzardContext.wizzardGlobal.StasamCounting;
            if (inputRequest) {
                setShowCountListGeneration(true);
                createStasamCountList(selectedPopulation?.populationId, inputRequest,
                    response => {
                        setShowCountListGeneration(false);
                        showReportPageCountWarningIfNeeded(response);
                    },
                    onError,
                    cancelTokenSource.token
                );
                wizzardContext.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetStasamCounting, payload: null
                });
            }
        }
    }, [cancelTokenSource.token, onError, selectedPopulation?.populationId, showReportPageCountWarningIfNeeded, wizzardContext]);

    React.useEffect(() => {
        on(wizardEvents.countingDowloadButtonClicked, downloadButtonClicked);

        return () => {
            off(wizardEvents.countingDowloadButtonClicked, downloadButtonClicked);
        }
    }, [downloadButtonClicked]);

    React.useEffect(() => {
        if (selectedValue !== undefined) {
            wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetCountNextButton, payload: { CountingOption: selectedValue } });

            if (selectedValue === window.enumCountingOption.ExternalEditing && !defaultExportFileName) {
                fetchDefaultCountListFileName(selectedPopulation?.populationId, onError)
                    .then(res => setDefaultExportFileName(res));
            }
        }
    }, [selectedValue]);

    React.useEffect(() => {
        generateCountList();
        setLoading(false);
        return () => { cancelTokenSource.cancel(); }
    });

    const onErpSystemFeedback = (message) => {
        if (
            selectedPopulation?.populationId.toLowerCase() === message.populationId.toLowerCase()
        ) {
            wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetCountNextButton, payload: { CountingOption: window.enumCountingOption.OnMobileDevice } });
            trigger(wizardEvents.stepFinalized, { goToStep: wizardStep.Counting });
        }
    }

    const pollErpSystemFeedback = () => {
        getCurrentProgramLockStateAndDoActions(selectedPopulation?.populationId,
            data => {
                if (!data.waitingForErp && waitingForErp) {
                    wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetCountNextButton, payload: { CountingOption: window.enumCountingOption.OnMobileDevice } });
                    trigger(wizardEvents.stepFinalized, { goToStep: wizardStep.Counting });
                }
            },
            error => {
                console.log('error polling ERP system feedback', error);
            }
        );
    }

    const handlePageWarningClose = (_isConfirmed) => {
        setIsPageCountWarningVisible(false);
    };

    const pageWarningText = wizzardContext.wizzardGlobal.InterfaceConfiguration?.interfaceConfigurationId
        ? intl.formatMessage({ id: 'SelectCountingOption.PageCountWarningWithAssignedInterfaceConfig' }, { 'Count': wizzardContext.wizzardGlobal.InterfaceConfiguration.exportCountItems?.numberOfPositionsOnPage ?? '' })
        : intl.formatMessage({ id: 'SelectCountingOption.PageCountWarningWithoutAssignedInterfaceConfig' }, { 'Count': expectedPageCount });

    const linkToInterfaceConfiguration = <Link to={`/administration/config/${wizzardContext.wizzardGlobal.InterfaceConfiguration?.interfaceConfigurationId}/${1}/${true}`} > {wizzardContext.wizzardGlobal.InterfaceConfiguration?.interfaceConfigurationName}</Link>;

    return (
        <div>
            <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title={<FormattedMessage id='SelectCountingOption.ChooseCountingOption' />}>
                    <Row className="wizard-content">
                        <Col xs="12">
                            <Row>
                                <Col xs="4">
                                    <label htmlFor="countHere" className="select-card">
                                        <Card className={selectedValue === window.enumCountingOption.ContinueHere ? "compare-options card-selected" : "compare-options"}>
                                            <CardHeader>
                                                <h2><FormattedMessage id='SelectCountingOption.ContinueHere' />
                                                    <div className="float-right">
                                                        <RadioButton
                                                            name="ChooseDataQualityRadio"
                                                            value={window.enumCountingOption.ContinueHere}
                                                            checked={wizzardContext.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.ContinueHere}
                                                            onChange={handleChange}
                                                            id="countHere"
                                                        />
                                                    </div>
                                                </h2>
                                            </CardHeader>
                                            <CardBody>
                                                <FontAwesomeIcon icon={faMapMarkerAlt} size='lg' />
                                                &nbsp; <FormattedMessage id='SelectCountingOption.CountItemsWith' /> <b><FormattedMessage id='SelectCountingOption.STATCONTROLCloud' /></b>
                                            </CardBody>
                                        </Card>
                                    </label>
                                </Col>
                                <Col xs="4" >
                                    <label htmlFor="countExternally" className="select-card">
                                        <Card className={selectedValue === window.enumCountingOption.ExternalEditing ? "compare-options card-selected" : "compare-options"}>
                                            <CardHeader>
                                                <h2><FormattedMessage id='SelectCountingOption.ExternalEditing' />
                                                    <div className="float-right">
                                                        <RadioButton
                                                            name="ChooseDataQualityRadio"
                                                            value={window.enumCountingOption.ExternalEditing}
                                                            checked={wizzardContext.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.ExternalEditing}
                                                            onChange={handleChange}
                                                            id="countExternally"
                                                        />
                                                    </div>
                                                </h2>
                                            </CardHeader>
                                            <CardBody>
                                                <FontAwesomeIcon icon={faPaperPlane} size='lg' />
                                                &nbsp; <b><FormattedMessage id='SelectCountingOption.DownloadEdit' /></b> <FormattedMessage id='SelectCountingOption.InventoryItemsThirdParty' />
                                            </CardBody>
                                        </Card>
                                    </label>
                                </Col>
                                <Col xs="4" >
                                    <label htmlFor="countOnMobile" className="select-card">
                                        <Card className={selectedValue === window.enumCountingOption.OnMobileDevice ? "compare-options card-selected" : "compare-options"}>
                                            <CardHeader>
                                                <h2><FormattedMessage id={wizzardContext.wizzardGlobal.InterfaceConfiguration?.connectedToErp ? 'SelectCountingOption.OnErp' : 'SelectCountingOption.OnMobileDevice'} />
                                                    <div className="float-right">
                                                        <RadioButton
                                                            name="ChooseDataQualityRadio"
                                                            value={window.enumCountingOption.OnMobileDevice}
                                                            checked={wizzardContext.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.OnMobileDevice}
                                                            onChange={handleChange}
                                                            id="countOnMobile"
                                                        />
                                                    </div>
                                                </h2>
                                            </CardHeader>
                                            <CardBody>
                                                <FontAwesomeIcon icon={faMobile} size='lg' />
                                                &nbsp;&nbsp;
                                                <FormattedMessage id='SelectCountingOption.CountItemsWithSmartPhone' /> <b><FormattedMessage id='SelectCountingOption.Smartphone' /></b>

                                            </CardBody>
                                        </Card>
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {(waitingForErp && !showCountListGeneration) &&
                        <Row>
                            <WaitingForErp />
                            <SignalRNotificationHandler
                                notificationType='ErpSystemFeedback'
                                onNotification={onErpSystemFeedback}
                                notificationFallbackMethod={pollErpSystemFeedback}
                                pollingIntervalMilliseconds={5000}
                            />
                        </Row>
                    }
                </TabStripTab>
                <TabStripTab title={<FormattedMessage id='Reporting.CountList' />} disabled={showCountListGeneration || wizzardContext.wizzardGlobal.Import.IsBackgroundTaskFinishedWithError}>
                    <div>
                        {isCustomerWithoutLicense && (
                            <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                        )}
                        {!isCustomerWithoutLicense && (
                            <PreviewPDF file={fileCountlist}
                                prevPageText='<'
                                nextPageText='>'
                                downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                            />
                        )}
                    </div>
                </TabStripTab>
            </TabStrip>

            {
                showCountListGeneration &&
                <Dialog className='background-task-dialog'
                    title={<FormattedMessage id='Reporting.CountList' />}
                >
                    <div className="text-center">
                        <p>
                            <FormattedMessage id='SelectCountingOption.CountListGenerating' />
                        </p>
                        <Loader themeColor="light" type="infinite-spinner" size="large" />
                    </div>
                </Dialog>
            }

            <ConfirmDialog
                visible={isPageCountWarningVisible}
                onClose={handlePageWarningClose}
                negative={<FormattedMessage id='Control.Ok' />}
                title={<FormattedMessage id='SelectCountingOption.PageCountWarningTitle' />}
            >
                <p>
                    {pageWarningText}
                </p>
                <p>
                    {linkToInterfaceConfiguration}
                </p>
            </ConfirmDialog>


        </div>
    );
};

SelectCountingOption.propTypes = {
    goToImportCounting: PropTypes.func.isRequired,
    handleError: PropTypes.func
};

export default SelectCountingOption;
