export const data = [
    {
        id: "9.29",
        title: "Release 9.29",
        sections: [
            {
                title: "Features",
                id: "1",
                changes:
                [
                    {
                        workitem: "204489",
                        text: "'Subscription' and 'Contact Us' and buttons with icons in the main navigation bar"
                    },
                    {
                        workitem: "204490",
                        text: "'Statistics' widget was removed from the main dashboard"
                    },
                    {
                        workitem: "204491",
                        text: "<i>Continue inventory</i> action icon is changed to <i>Eye</i> on the main dashboard, when the inventory is entirely finalized. It's tooltip is also changed to <i>View inventory results</i> in this case"
                    },
                    {
                        workitem: "204492",
                        text: "Reports history: Documents are listed in descending order of the creation date"
                    },
                    {
                        workitem: "204493",
                        text: "<p><i>Countlist parameters</i> wizard step is skipped for periodic inventories</p><p>The Countlist parameters are displayed on the pre-analysis dashboard for periodic inventories with high quality sampling</p>"
                    },
                    {
                        workitem: "204494",
                        text: "New button, <i>Differences</i>, was added to the counting step of the inventory wizard, allowing the instant generation and inquiry of the differences list"
                    },
                    {
                        workitem: "204495",
                        text: "<i>Continue</i> button is disabled on the counting table, until there are enough samples per stratum (post-analysis reports can be created). Confirmation dialog on <i>Continue</i> button click."
                    },
                    {
                        workitem: "209786",
                        text: "New tab on <i>SupportTools</i> to schedule/cancel the adjustment of the elastic pools of all customer databases"
                    },
                    {
                        workitem: "209097",
                        text: "New setting: <b>Default export filename</b> on <i>Population</i> tab of the <i>Settings</i> page, to use as default when selecting the filename for countlist export, using <i>External Counting</i>"
                    }
                ]
            },
            {
                title: "Bugfixes",
                id: "2",
                changes:
                    [
                        {
                            workitem: "84693",
                            text: "Improvements on report generation with huge amount of imported data"
                        },
                        {
                            workitem: "209171",
                            text: "Report pdf files are rendered directly in Azure blob storage instead of files on disk"
                        },
                        {
                            workitem: "209088",
                            text: "<i>Differences list grouped</i> report pdf document can be loaded on the last step of the Inventory Wizard (Reports)"
                        },
                        {
                            workitem: "209090",
                            text: "<i>Balance sheet date</i> on the Preparation page of the wizard, is automatically adjusted to the default value set in customer settings, if it is older than 2 months from now"
                        }
                    ]
            }
        ]
    },
    {
        id: "9.28",
        title: "Release 9.28",
        sections: [
            {
                title: "Bugfixes",
                id: "1",
                changes:
                    [
                        {
                            workitem: "204198",
                            text: "Fixing 'wrong file format' error on stock update"
                        },
                        {
                            workitem: "203952",
                            text: "Update Remira.UCP.Mail.Client package to version 0.1.30"
                        }
                    ]
            }
        ]
    },
    {
        id: "9.27",
        title: "Release 9.27",
        sections: [
            {
                title: "Features",
                id: "1",
                changes:
                    [
                        {
                            workitem: "170122",
                            text: "Removed automatic end of inventory."
                        }
                    ]
            }
        ]
    },
    {
        id: "9.26",
        title: "Release 9.26",
        sections: [
            {
                title: "Features",
                id: "1",
                changes:
                    [
                        {
                            workitem: "123978",
                            text: "Enable sampling count on high quality inventory parallel to intial sampling"
                        }
                    ]
            }
        ]
    },
    {
        id: "9.25",
        title: "Release 9.25",
        sections: [
            {
                title: "Features",
                id: "1",
                changes:
                    [
                        {
                            workitem: "108433",
                            text: "Lock icon indicates population's FeedbackCompletedTotal program lock state on the dashboard"
                        },
                        {
                            workitem: "121905",
                            text: "Support phone number was changed"
                        },
                        {
                            workitem: "106690",
                            text: "Confirmation needed to continue on wizard preparation page, if the balance sheet date is in the past"
                        },
                        {
                            workitem: "108429",
                            text: "Continue counting on the page according to the last selected counting option"
                        }
                    ]
            },
            {
                title: "Bugfixes",
                id: "2",
                changes:
                    [
                        {
                            workitem: "105856",
                            text: "Warning popup if the rendered count list or differences list contains unexpected page breaks because of too large page data. Page counting on the pdf document is based on the defined pages, instead of the rendered ones."
                        },
                        {
                            workitem: "122471",
                            text: "Newer TLDs are included in email address validation during registration (i.e. <i>.group</i>) <br/> The error result files with <b>.txt</b> extension, now can be downloaded from the reports browser"
                        },
                        {
                            workitem: "122741",
                            text: "Newer TLDs are included in email address validation (i.e. <i>.group</i>) also on the <i>Administration -> User Management page</i>"
                        }
                    ]
            }
        ]
    },
    {
        id: "9.24",
        title: "Release 9.24",
        sections: [
            {
                title: "Bugfixes",
                id: "2",
                changes:
                    [
                        {
                            workitem: "40858",
                            text: "The browser won't display a confirmation dialog about unsaved changes on login or navigating away from the application for any reason. <br/> The selected customer is not changing automatically to the default (last used) population's customer when a new population is added or an existing population is deleted"
                        },
                        {
                            workitem: "108254",
                            text: "Mobile device editors doesn't allow in the device name characters that are invalid in a filename, to eliminate the problems encountered with the countlist feedback report filenames containing invalid characters (for a filename) <br/> At the report generation side, the invalid characters are automatically replaced by underscore character in the report filenames"
                        },
                        {
                            workitem: "108485",
                            text: "The group separator set to <b>None</b> in the assigned interface configuration of the population (<i>Import of count quantities</i> tab) is correctly applied on the counted quantities editing table"
                        },
                        {
                            workitem: "42520",
                            text: "Fixed different timestamps in report file name and history. UTC and DST"
                        },
                        {
                            workitem: "108617",
                            text: "Fixed wrong total sum on difference list"
                        }                    ]
            },
            {
                title: "Technical",
                id: "3",
                changes:
                    [
                        {
                            workitem: "108424",
                            text: "Update of some Azure nuget packages."
                        }
                    ]
            }
        ]
    },
    {
        id: "9.23",
        title: "Release 9.23",
        sections: [
            {
                title: "Bugfixes",
                id: "1",
                changes:
                    [
                        {
                            workitem: "107560",
                            text: "Fixed the email problem"
                        },
                        {
                            workitem: "40858",
                            text: "The browser won't display a confirmation dialog about unsaved changes on login or navigating away from the application for any reason. <br/> The selected customer is not changing automatically to the default (last used) population's customer when a new population is added or an existing population is deleted"
                        }
                    ]
            },
            {
                title: "Database",
                id: "2",
                changes:
                    [
                        {
                            workitem: "107897",
                            text: "Handle multiple ElasticPools"
                        }
                    ]
            }            ,
            {
                title: "Inventory",
                id: "3",
                changes:
                    [
                        {
                            workitem: "107442",
                            text: "The save file dialog is filtered by .csv or .txt extension when downloading the count list and the differences list, depending on the imported data file extension."
                        }
                    ]
            }
        ]
    },
    {
        id: "9.22",
        title: "Release 9.22",
        sections: [
            {
                title: "Bugfixes",
                id: "1",
                changes:
                    [
                        {
                            workitem: "107238",
                            text: "HOTFIX: ReportDashBoard - Allow items to draw (even there aren't items to REdraw) on perpetual inventory."
                        },
                        {
                            workitem: "106220",
                            text: "ReportDashBoard - Items to redraw are offered although not all items have been counted"
                        },
                        {
                            workitem: "106133",
                            text: "Counted quantity of \"0\" is correctly displayed in the counting table (instead of empty field)"
                        },
                        {
                            workitem: "106315",
                            text: "Counting table: clicking the Continue button won't cause the recently entered quantity modifications to disappear from the cells. Counted quantity can be deleted for the not yet counted items"
                        },
                        {
                            workitem: "106494",
                            text: "Grouping: The grouping field wasn't applied to the population."
                        },
                        {
                            workitem: "106771",
                            text: "Decimal- and group separator handling fix on the feedback page, when editing actual quantities. Safety valve in report generator and renderer to handle null values for ident fields and item description; Display the selected report currency value on the Preparation step (when loading and when going backward to it in the wizard)"
                        },
                        {
                            workitem: "106772",
                            text: "Fixing attempt and improved logging on the browser console, regarding the following occasional issues: Continue button click has no effect on mobile counting table, import background task indicator dialog is not displayed when import starts, but UnexpectedProgramLockStateException. Text modification in the inventory reset confirmation dialog, when clicking the Previous button on the pre-analysis dashboard"
                        }
                    ]
            },
            {
                title: "Feedback API",
                id: "2",
                changes:
                    [
                        {
                            workitem: "105931",
                            text: "\"Perpetual Inventory Sample\" is unavailable in the \"Inventory type\" dropdown list for ERP-connected populations, on the Preparation page of the inventory wizard."
                        },
                        {
                            workitem: "105931",
                            text: "Automatic forward of the counting type selection step to the mobile counting table, when feedback is received from ERP (for ERP-connected populations waiting for ERP system's feedback)"
                        },
                        {
                            workitem: "105931",
                            text: "\"Refresh\" button is visible on the \"Counting on mobile/ERP\" table and can be used to reload the table content in case the automatic refresh on ERP system feedback reception doesn't work, due to signalR communication failure"
                        },
                        {
                            workitem: "105931",
                            text: "\"Continue\" button behavior added to the \"Counting on mobile/ERP\" table, to continue an ERP- connected inventory when the countings were finished"
                        },
                        {
                            workitem: "106080",
                            text: "\"SetDeviceNames\" marked obsolete, replaced by \"SetDeviceNamesByERP\""
                        }
                    ]
            },
            {
                title: "Inventory",
                id: "3",
                changes:
                    [
                        {
                            workitem: "105952",
                            text: "Reset the inventory when clicking on the previous button on the pre-analysis step of the inventory wizard."
                        },
                        {
                            workitem: "105885",
                            text: "Add validation for the presence of at least one unique id column and the quantity column during the data import and when creating the mappings for the interface configuration."
                        },
                        {
                            workitem: "89922",
                            text: "Use the save file dialog when downloading the count list and the differences list."
                        }
                    ]
            },
            {
                title: "Dashboard",
                id: "4",
                changes:
                    [
                        {
                            workitem: "106493",
                            text: "Dashboard performance improvement - Caching population statistics data on first loading of customer information."
                        }
                    ]
            }

        ]
    },
    {
        id: "9.21",
        title: "Release 9.21",
        sections: [
            {
                title: "Bugfixes",
                id: "1",
                changes:
                    [
                        {
                            workitem: "89714",
                            text: "Feedback report - using device name and token in report subtitle, history and logging."
                        }
                    ]
            },
            {
                title: "Feedback API",
                id: "2",
                changes:
                    [
                        {
                            workitem: "89886",
                            text: "New Feedback API methods were added, using the mapped column names of the ident fields instead of the internal Fields enum values, while downloading device count lists and uploading feedback for ERP connected inventories (require authorization)."
                        }
                    ]
            },
            {
                title: "Technical changes",
                id: "3",
                changes:
                    [
                        {
                            workitem: "84692",
                            text: "Provide test data for the changed Lorenz curve calculation. See item 76269 below."
                        },
                        {
                            workitem: "90058",
                            text: "Removed stored procedure 'Strukturanalyse'. Changed the calculation of the structur analysis so that lower value classes are more detailed."
                        }
                    ]
            },
            {
                title: "Interface Configurations",
                id: "4",
                changes:
                    [
                        {
                            workitem: "90074",
                            text: "Changes to the 'Export of count items' tab of the editor: Added the possibility to edit the 'Change page after X characters' for identity columns, additional columns and the Stratum column. Added the possibility to edit the 'Page break after X positions'. Removed the 'Count lists' tab from the Settings page."
                        },
                        {
                            workitem: "90087",
                            text: "Changes to the 'Export of count items' tab of the editor: Added the possibility to edit the sort order of the assigned columns."
                        },
                        {
                            workitem: "90093",
                            text: "Changes to the 'Export of accounting data' tab of the editor: Added the possibility to edit the 'Change page after X characters' for identity columns, additional columns and the Stratum column. Added the possibility to edit the sort order of the assigned columns. Added the possibility to edit the 'Page break after X positions'."
                        }
                    ]
            },
            {
                title: "Technical",
                id: "5",
                changes:
                    [
                        {
                            workitem: "90058",
                            text: "Removed stored procedure 'Strukturanalyse'. Changed the calculation of the structur analysis so that lower value classes are more detailed."
                        }
                    ]
            }        ]
    },
    {
        id: "9.20",
        title: "Release 9.20",
        sections: [
            {
                title: "Bugfixes",
                id: "1",
                changes:
                    [
                        {
                            workitem: "42464",
                            text: "Fix the error on High Quality inventory when grouping is enabled."
                        },
                        {
                            workitem: "42300",
                            text: "Fix the error, that the sum of items displayed in the protocol of stratification is wrong. This happend, when a stratum with zero items exist (Import of MTW_all2.csv)."
                        },
                        {
                            workitem: "42431",
                            text: "If the first column to be exported is not column 1, empty columns are inserted according to the column number of the first exported column."
                        },
                        {
                            workitem: "42183",
                            text: "Perpetual inventory wizard - Continue btn click on the counting table page waits for the finalization of the SaveCountingList before advancing to the next page, avoiding the misplaced indication of a 'Less than 2 counts reported' error notification on the reporting dashboard"
                        },
                        {
                            workitem: "42191",
                            text: "Fix to the value shown for the error weight on the data quality selection step of the inventory wizard."
                        },
                        {
                            workitem: "42298",
                            text: "Column header names read from the data upload file are trimmed before mapping them to the interface configuration definitions, avoiding errors caused by additional space characters before/after column header names"
                        },
                        {
                            workitem: "42260",
                            text: "High quality data (staseq) inventory maximum number of total items to count is calculated in the same way on the UI as in the Core"
                        },
                        {
                            workitem: "42521",
                            text: "If an interface configuration is assigned to the population, then on the Counting feedback page the decimal and thousand separators used in the input fields are taken from the interface configuration - \"Import of count quantities\" parameters"
                        },
                        {
                            workitem: "42833",
                            text: "The interface configuration tab text \"Export von Buchhaltungsdaten\" was changed into \"Export von Buchungsdaten\""
                        },
                        {
                            workitem: "42539",
                            text: "Perpetual display of the background import running indicator popup after a successful initial data import for a \"Complete Inventory\", was fixed. Also, the pdf report preview tabs on the pre-analysis dashboard are disabled for the \"Complete Inventory\" type (except the \"PDF View\" tab)."
                        },
                        {
                            workitem: "76269",
                            text: "Autocomplete Lorenz curve input data so that the graphic chart always reach the abscissa 100, preventing this way the infinite loop bug ocurring for chart data reaching 100% for too small abscissa values."
                        }
                    ]
            },
            {
                title: "Inventory",
                id: "2",
                changes:
                    [
                        {
                            workitem: "42214",
                            text: "Renamed inventory type 'Permanente Inventur' to 'Permanente Stichprobeninventur'."
                        },
                        {
                            workitem: "42184",
                            text: "'Perpetual inventory completed' notification on reporting dashboard when the program lock state is FeedbackCompletedRun (6) and a new option - End partial counting and go to dashboard - on the option selector dialog popping up on 'Continue' button click"
                        },
                        {
                            workitem: "41990",
                            text: "The export files get the same extension as the data file had at the inventory data import"
                        }
                    ]
            },
            {
                title: "Interface Configurations",
                id: "3",
                changes:
                    [
                        {
                            workitem: "63125",
                            text: "Manage the new ConnectedToErp flag on interface configurations"
                        }
                    ]
            },
            {
                title: "Technical",
                id: "4",
                changes:
                    [
                        {
                            workitem: "42433",
                            text: "Replace stored procedure 'Stratification' by C# code."
                        }
                    ]
            },
            {
                title: "Support tools",
                id: "5",
                changes:
                    [
                        {
                            workitem: "42355",
                            text: "A new table (NLS) was created in the admin-db, storing predefined localizable text definitions, manageable through a new user interface on the Support Tools page. The main dashboard displays a hint text in orange, if the text defined in the NLS table under the key named 'NLS_DashboardHint' is not empty"
                        },
                        {
                            workitem: "42502",
                            text: "Ability to run predefined support scripts on admin-db"
                        }
                    ]
            }
        ]
    },
    {
        id: "9.19",
        title: "Release 9.19",
        sections: [
            {
                title: "Bugfixes",
                id: "1",
                changes:
                    [
                        {
                            workitem: "41451",
                            text: "Export with fixed record format is possible."
                        },
                        {
                            workitem: "41936",
                            text: "Reaching the rejection limit does result in the end of the inventory."
                        },
                        {
                            workitem: "41983",
                            text: "Interface configuration: When making changes to the column name and report name of the assigned columns on the 'Import of inventory data' tab, update the values in the assigned columns section on all the other tabs."
                        },
                        {
                            workitem: "41586",
                            text: "Page breaks on CountList pdf document."
                        },
                        {
                            workitem: "41587",
                            text: "Fix to the count list creation error."
                        },
                        {
                            workitem: "41511",
                            text: "Population settings are correctly loaded."
                        },
                        {
                            workitem: "41437",
                            text: "Counting table column widths are smaller."
                        },
                        {
                            workitem: "41542",
                            text: "Interface Configuration: the saved custom column separator is being used."
                        },
                        {
                            workitem: "41433",
                            text: "Fix to the feedback table fixed columns transparency."
                        },
                        {
                            workitem: "41441",
                            text: "Fix to the previous button click on the feedback page."
                        },
                        {
                            workitem: "41456",
                            text: "Fix to the assignment of wrong interface configuration after import."
                        },
                        {
                            workitem: "41431",
                            text: "The new user is created with the role selected on the UI."
                        },
                        {
                            workitem: "41514",
                            text: "Fix to the add user bug when typing in the logged in user's email address."
                        },
                        {
                            workitem: "41512",
                            text: "Fix to the deletion of a customer having interface configuration defined."
                        },
                        {
                            workitem: "41299",
                            text: "The preparation page of the inventory wizard was simplified."
                        },
                        {
                            workitem: "41529",
                            text: "Using the custom column separator during the import file header processing."
                        },
                        {
                            workitem: "42104",
                            text: "High Quality data inventory - Inventory status is signaled correctly on the reporting dashboard."
                        },
                        {
                            workitem: "42105",
                            text: "Fix import error on Manual Matching Process."
                        },
                        {
                            workitem: "42089",
                            text: "Interface Configuration: Rows in default file configuration are not checked."
                        },
                        {
                            workitem: "42054",
                            text: "Disable the 'End inventory' option when there is no feedback."
                        },
                    ]
            },
            {
                title: "Authentication",
                id: "2",
                changes:
                    [
                        {
                            workitem: "41756",
                            text: "Move to Keycloak version 20 and use separate realms for the Dev and QA environments."
                        }
                    ]
            },
            {
                title: "User Rights",
                id: "3",
                changes:
                    [
                        {
                            workitem: "41432",
                            text: "Enable reports for Operator user."
                        }
                    ]
            }
        ]
    },
    {
        id: "9.18",
        title: "Release 9.18",
        sections: [
            {
                title: "Warehouse Management",
                id: "1",
                changes:
                    [
                        {
                            workitem: "41546",
                            text: "Inline editing: Instead of opening a popup form to edit the warehouse location details, these can be edited inline in the grid. Also, adding a new warehouse location can be performed in the same way."
                        }
                    ]
            },
            {
                title: "Interface Configuration",
                id: "2",
                changes:
                    [
                        {
                            workitem: "40835",
                            text: "The 'Price Unit' interface configuration parameter was renamed to 'Price divided by' on the UI. Also, the 'Variable' value for this parameter was renamed to 'Value'."
                        }
                    ]
            },
            {
                title: "Inventory",
                id: "3",
                changes:
                    [
                        {
                            workitem: "41698",
                            text: "The default value of the Balance Sheet Date on the Preparation page of the inventory wizard, is the last day of the current year."
                        },
                        {
                            workitem: "41741",
                            text: "When counting on mobile, the QR code is disabled until the device gets activated."
                        }
                    ]
            },
            {
                title: "Bugfixes",
                id: "4",
                changes:
                    [
                        {
                            workitem: "40905",
                            text: "Eliminated the differences in rounding between difference list report and difference list export."
                        },
                        {
                            workitem: "40920",
                            text: "Unexpected automatic logout: Renewal of the authentication token has been improved by handling some non-fatal errors that could cause the automatic logout of the user even if the authentication token was in fact successfully renewed."
                        },
                        {
                            workitem: "41436",
                            text: "Fix unexpected export of position and page number in count list."
                        },
                        {
                            workitem: "41785",
                            text: "If the provisioning wasn't yet done for a user just registered in Keycloak, it is automatically starting on the first login."
                        }
                    ]
            },
            {
                title: "Miscellaneous",
                id: "5",
                changes:
                    [
                        {
                            workitem: "41806",
                            text: "Updated the AGB/General Terms and Conditions document that is available on the registration page."
                        }
                    ]
            }
        ]
    }
];
