import { createContext } from "react";

export const WizzardContext = createContext({
    Counting: { },
    SupportData: { },
    Import: {
        ProgramLockState: 0,
        BackgroundTaskStatus: 0 //window.enumBackgroundTaskStatus.NoBackgroundTaskRunning
    },
    CsvMapper: {
        importFile: {},
        columnHeaders: [],
        importedColumns: [],
        mapToUniqueId: [],
        mapToAmount: [],
        mapToPrice: [],
        mapToQuantityUnit: [],
        mapToPriceUnit: [],
        mapToArticleDescription: [],
        mapToAdditionalFields: [],
        draggedItem: {}
    },
    InterfaceConfiguration: {},
    CustomerSettings: {},
    Reliabilities: [],
    SequentialTestItemsToCount: 0,
    InitialImport: false
});

export const WIZZARD_ACTIONS = {
    SetCountNextButton: 'SetCountNextButton',
    SetImportFile: 'SetImportFile',
    SetCountData: 'SetCountData',
    SetItem: 'SetItem',
    SetHeader: 'SetHeader',
    SetTotalPages: 'SetTotalPages',
    SetPage: 'SetPage',
    SetDataForSupport: 'SetLogDataForSupport',
    SetProgramLockState: 'SetProgramLockState',
    SetCsvMapperState: "CsvMapperState",
    SetInterfaceConfiguration: "SetInterfaceConfiguration",
    SetCustomerSettings: "SetCustomerSettings",
    SetStaseqCounting: "SetStaseqCounting",
    SetStasamCounting: "SetStasamCounting",
    SetInitialImport: "SetInitialImport",
    SetShowColumnMapping: "SetShowColumnMapping",
    SetInvetoryHeaderInfo: 'SetInvetoryHeaderInfo',
    SetCountDeviceActive: 'SetCountDeviceActive',
    SetReportsCannotBeCreatedMessage: 'SetReportsCannotBeCreatedMessage'
}

export function WizzardReducer(state, action) {
    switch (action.type) {
        case WIZZARD_ACTIONS.SetCountNextButton:
            {
                let currentState = { ...state };
                currentState.Counting.CountingOption = action.payload.CountingOption;
                return currentState;

            }
        case WIZZARD_ACTIONS.SetDataForSupport:
            {
                let currentState = { ...state };
                currentState.SupportData = {
                    ...currentState.SupportData,
                    ...action.payload.SupportData
                };
                return currentState;
            }
        case WIZZARD_ACTIONS.SetImportFile:
            {
                let currentState = { ...state };
                if (action.payload.ImportFile) {
                    currentState.Counting.CurrentImportFile = action.payload.ImportFile;
                }
                else {
                    currentState.Counting.CurrentImportFile = {};
                }

                return currentState;
            }
        case WIZZARD_ACTIONS.SetTotalPages:
            {
                let currentState = { ...state };
                currentState.Counting.TotalPages = action.payload.TotalPages;

                return currentState;
            }
        case WIZZARD_ACTIONS.SetCountData:
            {
                let currentState = { ...state };
                currentState.Counting.CountData = action.payload.CountData;

                return currentState;
            }      
        case WIZZARD_ACTIONS.SetHeader:
            {
                let currentState = { ...state };
                currentState.Counting.Header = action.payload.Header;

                return currentState;
            }
        case WIZZARD_ACTIONS.SetItem:
            {
                let currentState = { ...state };
                currentState.Counting.CountData = state.Counting.CountData.map(item => {
                    if (item.ItemNo === action.payload.item.ItemNo) {
                        action.payload.item.ActualQuantityHasChanged = true;
                        return action.payload.item;
                    }
                    else {
                        return item;
                    }
                })
                return currentState;
            }
        case WIZZARD_ACTIONS.SetPage:
            {
                let currentState = { ...state };
                currentState.Counting.Page = action.payload.Page;

                return currentState;
            }
        case WIZZARD_ACTIONS.SetCountDeviceActive:
            {
                let currentState = { ...state };
                currentState.Counting.IsDeviceActive = action.payload.IsDeviceActive;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetReportsCannotBeCreatedMessage:
            {
                let currentState = { ...state };
                currentState.Counting.ReportsCannotBeCreatedMessage = action.payload.ReportsCannotBeCreatedMessage;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetProgramLockState:
            {
                let currentState = { ...state };
                currentState.Import.ProgramLockState = action.payload.ProgramLockState;
                currentState.Import.BackgroundTaskStatus = action.payload.BackgroundTaskStatus;
                currentState.Import.IsBackgroundTaskRunning = action.payload.IsBackgroundTaskRunning;
                currentState.Import.IsBackgroundTaskFinishedWithError = action.payload.IsBackgroundTaskFinishedWithError;
                currentState.Import.LastError = action.payload.LastError;
                currentState.Import.InventoryMethod = action.payload.InventoryMethod;
                currentState.Import.FirstMissingFeedbackPageNr = action.payload.FirstMissingFeedbackPageNr;
                currentState.Import.WaitingForErp = action.payload.WaitingForErp;
                currentState.Import.CountingOption = action.payload.CountingOption;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetCsvMapperState:
            {
                let currentState = { ...state };
                currentState.CsvMapper = action.payload;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetInterfaceConfiguration:
            {
                let currentState = { ...state };
                currentState.InterfaceConfiguration = action.payload;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetCustomerSettings:
            {
                let currentState = { ...state };
                currentState.CustomerSettings = action.payload;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetReliabilities:
            {
                let currentState = { ...state };
                currentState.Reliabilities = action.payload;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetStaseqCounting:
            {
                let currentState = { ...state };
                currentState.StaseqCounting = action.payload;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetStasamCounting:
            {
                let currentState = { ...state };
                currentState.StasamCounting = action.payload;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetInitialImport:
            {
                let currentState = { ...state };
                currentState.InitialImport = action.payload;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetShowColumnMapping:
            {
                let currentState = { ...state };
                currentState.ShowColumnMapping = action.payload;
                return currentState;
            }
        case WIZZARD_ACTIONS.SetInvetoryHeaderInfo:
            {
                let currentState = { ...state };
                currentState.HeaderInfo = action.payload;
                return currentState;
            }
        default:
            return state
    }
}
