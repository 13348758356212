import * as React from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import EditActionCell from "./EditActionCell";
import { process } from "@progress/kendo-data-query";
import { updatePopulation, deletePopulation, addPopulation } from "../../../services/warehouseLocationsService"
import { Card, CardHeader, CardBody, Button, Row, Col } from 'reactstrap';
import { WL_ACTIONS } from './services';
import ConfirmDialog from '../../Shared/ConfirmDialog';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import ReactTooltip from 'react-tooltip';
import { GetDisabledAddPopulationButtonTooltip, IsAddingPopulationAllowedBySubscription, RefreshUserContext } from '../../UserContext';
import GridFooter from '../../Shared/GridFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { DropDownCell } from '../../Shared/DropDownCell';
import { useSelector } from 'react-redux';
import { getInterfaceConfigurationsByCustomer } from '../../../services/interfaceConfigurationsService';

const editField = "inEdit";

const InterfaceConfigurationDropdown = props => (
    <DropDownCell
        {...props}
        dataSourceField="dataSource"
    />
);

const Population = props => {
    
    const intl = useIntl();
    const [dataWL, setDataWL] = React.useState({});
    const [data, setData] = React.useState([]);
    const [isLastPop, setIsLastPop] = React.useState(false);
    const [canAdd, setCanAdd] = React.useState(false);
    const [deleteConfVisibile, setDeleteConfVisibile] = React.useState(false);
    const [itemForDelete, setItemForDelete] = React.useState({});
    const dispatchNotification = useNotification();
    const pageId = 'pagePopulation';
    const [disable, setDisable] = React.useState(props.disable);
    const [dataState, setDataState] = React.useState({});
    const dispatchExceptionDialog = useExceptionDialog();
    const refreshUserContext = RefreshUserContext();
    const currentCustomer = useSelector(state => state.dashboard.customer);

    const isAddingPopulationAllowedBySubscription = IsAddingPopulationAllowedBySubscription();
    const getDisabledAddPopulationButtonTooltip = GetDisabledAddPopulationButtonTooltip();

    const [gridData, setGridData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState(null);
    const [interfaceConfigurations, setInterfaceConfigurations] = React.useState([]);

    const minGridLength = 5;

    React.useEffect(() => {
        return () => {
            removeNotification();
        };
    }, []);

    React.useEffect(() => {
        setDisable(props.disable)
    }, [props.disable]);

    React.useEffect(() => {
        if (props.dataItem?.populations) {
            updatePopulationData();
        }
        else {
            setCanAdd(false);
            setData([]);
            setDataWL({});
        }
    }, [props.dataItem, interfaceConfigurations]);

    React.useEffect(() => {
        if (currentCustomer == null)
            return;

        props.incrementLoadingCount();

        getInterfaceConfigurationsByCustomer(
            currentCustomer.customerId,
            data => {
                props.decrementLoadingCount();
                setInterfaceConfigurations(data.map(x => { return { value: x.interfaceConfigurationId, text: x.configurationName } }));
            },
            handleError);
    }, [currentCustomer.customerId]);

    React.useEffect(() => {
        setIsPagingVisible(data.length > minGridLength);
        setPageHandler(1);
    }, [data, itemsPerPage]);

    const updatePopulationData = () => {
        let temp = props.dataItem.populations.map(x => { return { ...x, dataSource: interfaceConfigurations ? interfaceConfigurations : [] } });
        if (editItem) {
            if (editItem.populationId) {
                let found = temp.filter((item) => item.populationId == editItem.populationId);
                if (found.length) {
                    found[0].inEdit = editItem.inEdit;
                }
            }
            else {
                temp = [editItem, ...temp];

            }
            if (temp.some((loc) => loc.inEdit)) {
                props.setDisable(true);
            }
        }

        setData(temp);
        setDataWL(props.dataItem);
        setCanAdd(true);
        setIsLastPop(props.dataItem.populations.length < 2);
    }

    const handleConfirmDialogClose = (result) => {
        if (result) {
            onConfirmDelete()
        }
        else {
            onCancelDelete()
        }
    }
    const onCancelDelete = () => {
        setDeleteConfVisibile(false);
    }

    const onConfirmDelete = () => {
        setDeleteConfVisibile(false);
        removeNotification();
        props.incrementLoadingCount();

        deletePopulation(
            itemForDelete.populationId,
            items => {
                refreshUserContext(handleError);
                props.dispatchWL({ type: WL_ACTIONS.RemovePopulation, payload: { id: dataWL.warehouseLocationId, populationId: itemForDelete.populationId } });
                props.decrementLoadingCount();
            },
            handleError);
    };

    const handleError = (errorMessage, showNotif = true) => {
        props.decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const remove = (dataItem) => {
        if (dataItem.importInProgress) {
            return false;
        }
        setDeleteConfVisibile(true)
        setItemForDelete(dataItem);
        props.setDisable(false);
    };

    const update = (dataItem) => {
        if (!isDataItemValid(dataItem)) {
            return;
        }
        removeNotification();
        props.incrementLoadingCount();
        updatePopulation(
            dataItem,
            _items => {
                dataItem.inEdit = false;
                setEditItem(null);
                props.dispatchWL({ type: WL_ACTIONS.UpdatePopulation, payload: { id: dataWL.warehouseLocationId, item: dataItem } });
                props.decrementLoadingCount();
            },
            handleError);
        props.setDisable(false);
    };

    const isDataItemValid = (dataItem) => {
        removeNotification();
        if (!dataItem.populationName) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="Population.PopulationNameReq" />
            })
            return false;
        }
        if (dataItem.populationName.length > 50) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="Population.PopulationNameLong" />
            })
            return false;
        }
        if (dataItem.description.length > 255) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="Population.DescriptionLong" />
            })
            return false;
        }
        if (data.filter(x => x.populationId !== dataItem.populationId).some(x => x.populationName.toLowerCase() === dataItem.populationName.toLowerCase())) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="Population.PopulationNameExists" />
            })
            return false
        }
        return true;
    };

    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
        setEditItem(null);
        props.setDisable(false);
    };

    const cancel = (dataItem) => {
        const originalItem = props.dataItem.populations.find(
            (p) => p.populationId === dataItem.populationId
        );

        originalItem.dataSource = interfaceConfigurations ? interfaceConfigurations : [];

        const newData = data.map((item) =>
            item.populationId === originalItem.populationId ? { ...originalItem, inEdit: false } : item
        );
        setData(newData);
        setEditItem(null)
        props.setDisable(false);
    };

    const enterEdit = (dataItem) => {
        dataItem.changed = false;
        props.setDisable(true);
        dataItem.inEdit = true;
        setEditItem(dataItem);
        setData(
            data.map((item) =>
                item.populationId === dataItem.populationId ? { ...item, inEdit: true } : item
            )
        );
    };

    const itemChange = (event) => {
        const newData = gridData.map((item) =>
            item.populationId === event.dataItem.populationId
                ? { ...item, [event.field || ""]: event.value, changed: true }
                : item
        );
        setGridData(newData);
    };

    const generateNewItem = () => {
        return {
            customerId: props.customerId,
            warehouseLocationId: dataWL.warehouseLocationId,
            populationName: '',
            description: '',
            populationId: '',
            interfaceConfigurations: null,
            dataSource: interfaceConfigurations ? interfaceConfigurations : []
        };
    }

    const handleAddPopulationButton = (_event) => {
        const newDataItem = generateNewItem();
        newDataItem.inEdit = true;
        setData([newDataItem, ...data]);
        props.setDisable(true);
        setEditItem(newDataItem);
        setTimeout(() => {
            let grids = document.getElementsByClassName('k-grid');
            if (grids.length > 0) {
                let grid = grids[1];
                let gridRows = grid.getElementsByClassName('k-grid-edit-row');
                if (gridRows.length > 0) {
                    let insertedRow = gridRows[0];
                    let inputs = insertedRow.getElementsByClassName('k-textbox')
                    if (inputs.length > 0) {
                        inputs[0].focus();
                    }
                }
            }
        });
    }


    const add = (newItem) => {
        if (!isDataItemValid(newItem)) {
            return;
        }
        removeNotification();
        props.incrementLoadingCount();
        newItem.inEdit = false;
        addPopulation(
            newItem,
            result => {
                refreshUserContext(handleError);
                setEditItem(null);
                newItem.populationId = result;
                props.setDisable(false);
                props.dispatchWL({ type: WL_ACTIONS.AddPopulation, payload: { id: dataWL.warehouseLocationId, item: newItem } });
                props.decrementLoadingCount();
            },
            handleError);
    }

    const setPageHandler = (page) => {
        let maxPage = Math.floor(Math.max(0, data.length - 1) / itemsPerPage) + 1;
        if (data && page > 0 && page <= maxPage) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;
            setGridData(data.slice(startIndex, endIndex));
            if (data.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
    }

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const EditRights = (props) => (
        <EditActionCell
            {...props}
            edit={enterEdit}
            remove={remove}
            discard={discard}
            add={add}
            update={update}
            cancel={cancel}
            editField={editField}
            idPropName={"populationId"}
            isLastPop={isLastPop}
            disabled={disable}
        />
    );

    return (
        <Card>
            <ConfirmDialog
                visible={deleteConfVisibile}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Delete' />}
                title={<FormattedMessage id='Population.DeleteConfirmationTitle' />}
                detail={intl.formatMessage({ id: "Population.ConfirmDeleteText" }, { 'PopulationName': itemForDelete.populationName })}
            />
            <CardHeader>
                <h3>
                    <FormattedMessage id='Population.Populations' />
                    <div className="header-customer-selection">
                        <small> {props.dataItem?.warehouseLocationName ?? ''}</small>
                    </div>
                    {canAdd && (
                        <>
                            <ReactTooltip id="populationAddTip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                            <div className="float-right">
                                <span data-tip={getDisabledAddPopulationButtonTooltip} data-for="populationAddTip">
                                    <Button color="primary" disabled={disable || !isAddingPopulationAllowedBySubscription} onClick={handleAddPopulationButton} data-tip={intl.formatMessage({ id: 'WarehouseLocation.AddPopulation' })} data-for="populationAddTip">
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </span>
                            </div>
                        </>
                    )}
                </h3>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <Grid data={process(gridData, dataState)}
                            {...dataState}
                            onDataStateChange={(e) => {
                                setDataState(e.dataState);
                            }}
                            onItemChange={itemChange}
                            editField={editField}
                            sortable={true}
                            key="populationId"
                            filterable={false}>
                            <GridNoRecords>
                                {dataWL.warehouseLocationId ? <FormattedMessage id="Population.AddPopInfo" /> : <FormattedMessage id="Population.SelectWarehouse" />}
                            </GridNoRecords>
                            <Column field="populationName" title={intl.formatMessage({ id: "Population.PopName" })} />
                            <Column field="description" title={intl.formatMessage({ id: "Population.DescriptionTitle" })} />
                            <Column field="interfaceConfigurationId" title={intl.formatMessage({ id: "Population.InterfaceConfiguration" })} cell={InterfaceConfigurationDropdown} />
                            <Column cell={EditRights} width="200px" />
                        </Grid>
                    </Col>
                </Row>
                {isPagingVisible && (
                    <GridFooter
                        itemsPerPage={itemsPerPage}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        dataLength={data.length}
                        setPageHandler={setPageHandler}
                        currentPage={currentPage}
                    />
                )}
            </CardBody>
        </Card>);
};

export default Population