import { post, get } from './serviceBase';

const baseUrl = '/api/v1.0/admin/UserProvision';

export function getProvisionType(successCallback, errorCallback) {
    const url = `${baseUrl}/GetProvisionType`;
    get(url, successCallback, errorCallback);
}

export function createDatabase(successCallback, errorCallback) {
    const url = `${baseUrl}/CreateDatabase`;
    post(url, null, successCallback, errorCallback);
}

export function createAdminDbMappings(databaseName, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('databaseName', databaseName);
    const url = `${baseUrl}/CreateAdminDbMappings?` + params;
    post(url, null, successCallback, errorCallback);
}

export function createBlobContainer(containerName, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('containerName', containerName);
    const url = `${baseUrl}/CreateBlobContainer?` + params;
    post(url, null, successCallback, errorCallback);
}

export function createUserMapping(successCallback, errorCallback) {
    const url = `${baseUrl}/CreateUserMapping`;
    post(url, null, successCallback, errorCallback);
}
