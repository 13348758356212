import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Card, CardBody, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumericTextBox, Switch, Input } from "@progress/kendo-react-inputs";
import { ConfigContext, CONFIG_CONTEXT_ACTIONS } from './ConfigContext';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { deepClone } from '../../../utils/jsonUtils';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import {
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import PropTypes from 'prop-types';

const InterfaceConfigurationParameters = ({params}) => {
    const pageId = 'InterfaceConfigurationParameters';
    const intl = useIntl();
    const configContext = React.useContext(ConfigContext);
    const dispatchNotification = useNotification();
    const [expanded, setExpanded] = React.useState(params.expanded === "true" || params.expanded === true);

    const [interfaceConfiguration, setInterfaceConfiguration] = useState(
        {
            startLine: 1,
            headingEnabled: false,
            priceEqualValue: false,
            columnSeparator: 0,
            decimalSeparator: 0,
            thousandSeparator: 0,
            priceUnit: 0,
            exportPositionAndPageNumbers: false,
            leadingZeros: false,
            customColumnSeparator: '',
            numberOfPositionsOnPage: 1
        });
    const [firstEdit, setFirstEdit] = useState(false);

    useEffect(() => {
        if (params.state) {
            setData();
        }
    }, [params.state]);

    useEffect(() => {
        setData();
    }, [params.doReset]);

    const setData = () => {
        setInterfaceConfiguration(deepClone(
            {
                ...params.state,
                interfaceConfigParamsType: params.interfaceConfigParamsType
            }
        ));

        configContext.dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetCurrentColumnSeparator,
            payload: params.state.columnSeparator
        });
        configContext.dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetCustomColumnSeparator,
            payload: params.state.customColumnSeparator
        });
        configContext.dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetModifiedParamsState,
            payload: params.state
        });

        setFirstEdit(false);
    };

    const handleValueChange = (event) => {
        if (event.value !== undefined) {
            let tempState = {
                ...interfaceConfiguration,
                [event.target.props.id]: event.value?.value !== undefined ? event.value.value : event.value
            };
            setInterfaceConfiguration(tempState);

            configContext.dispatchConfigGlobal({
                type: CONFIG_CONTEXT_ACTIONS.SetModifiedParamsState,
                payload: tempState
            });
        }

        if (params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities) {
            if (event.target.props.id === 'columnSeparator') {
                configContext.dispatchConfigGlobal({
                    type: CONFIG_CONTEXT_ACTIONS.SetCurrentColumnSeparator,
                    payload: event.value.value
                });
            }
            if (event.target.props.id === 'customColumnSeparator') {
                configContext.dispatchConfigGlobal({
                    type: CONFIG_CONTEXT_ACTIONS.SetCustomColumnSeparator,
                    payload: event.value.value ? event.value.value : event.value
                });
            }
        }

        if (!firstEdit) {
            setFirstEdit(true);
        }
    };

    const isImportConfig = useCallback(() => {
        return params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities;
    }, [params.interfaceConfigParamsType]);

    const isExportCountItemsOrAccountingData = useCallback(() => {
        return params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfCountItems || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfAccountingData;
    }, [params.interfaceConfigParamsType]);

    useEffect(() => {
        removeNotification();

        let isChanged = getIsChanged();
        params.setIsParamChanged(isChanged);

        let isValid = getIsValid();
        let isStartLineNeedsToBeBiggerThan1Valid = true;

        if (isImportConfig() && (interfaceConfiguration.headingEnabled || params.interfaceConfiguration.sap)) {
            isStartLineNeedsToBeBiggerThan1Valid = interfaceConfiguration.startLine >= 2;
            isValid = isValid && isStartLineNeedsToBeBiggerThan1Valid;
        }

        let areSeparatorsDifferentValid = true;
        if (isValid) {
            areSeparatorsDifferentValid = areSeparatorsDifferent();
            isValid = areSeparatorsDifferentValid;
        }

        let isNumberOfPositionsOnPageValid = true;
        if (isValid && isExportCountItemsOrAccountingData()) {
            isNumberOfPositionsOnPageValid = interfaceConfiguration.numberOfPositionsOnPage >= 1;
            isValid = isNumberOfPositionsOnPageValid;
        }

        if (!isStartLineNeedsToBeBiggerThan1Valid && firstEdit) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="InterfaceConfiguration.StartLineNeedsToBeBiggerThan1" />
            });
        }

        if (!areSeparatorsDifferentValid && firstEdit) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="InterfaceConfiguration.ThousandDecimalAndColumnSeparatorHaveToBeDifferent" />
            });
        }

        if (!isNumberOfPositionsOnPageValid && firstEdit) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="InterfaceConfiguration.NumberOfPositionsOnPageMustBeGreaterThanOne" />
            });
        }

        params.setIsParamValid(isValid);
        if (isValid) {
            params.setInterfaceConfigurationParameters(deepClone(interfaceConfiguration));
        }
    }, [interfaceConfiguration, isImportConfig, isExportCountItemsOrAccountingData]);

    const areSeparatorsDifferent = () => {
        if (configContext.configGlobal.columnSeparators.length > 0) {
            let columnSeparator = configContext.configGlobal.columnSeparators.find(x => x.value === interfaceConfiguration.columnSeparator)?.textValue;
            if (interfaceConfiguration.columnSeparator === window.enumColumnSeparator.Custom) {
                columnSeparator = configContext.configGlobal.customColumnSeparator;
            }
            let decimalSeparator = configContext.configGlobal.decimalSeparators.find(x => x.value === interfaceConfiguration.decimalSeparator)?.textValue;
            let thousandSeparator = configContext.configGlobal.thousandSeparators.find(x => x.value === interfaceConfiguration.thousandSeparator)?.textValue;

            return decimalSeparator !== thousandSeparator && columnSeparator !== thousandSeparator && columnSeparator !== decimalSeparator;
        }
        return true;
    };

    const getIsChanged = () => {
        return interfaceConfiguration.columnSeparator !== params.state.columnSeparator
            || interfaceConfiguration.decimalSeparator !== params.state.decimalSeparator
            || interfaceConfiguration.thousandSeparator !== params.state.thousandSeparator
            || interfaceConfiguration.headingEnabled !== params.state.headingEnabled
            || interfaceConfiguration.startLine !== params.state.startLine
            || interfaceConfiguration.priceUnit !== params.state.priceUnit
            || interfaceConfiguration.priceEqualValue !== params.state.priceEqualValue
            || interfaceConfiguration.customColumnSeparator !== params.state.customColumnSeparator
            || (interfaceConfiguration.exportPositionAndPageNumbers ?? false) !== (params.state.exportPositionAndPageNumbers ?? false)
            || (interfaceConfiguration.leadingZeros ?? false) !== (params.state.leadingZeros ?? false)
            || interfaceConfiguration.numberOfPositionsOnPage !== params.state.numberOfPositionsOnPage;
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const getIsValid = () => {
        removeNotification();
        if (params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData
            || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities) {
            return getObjForValue(configContext.configGlobal.columnSeparators, interfaceConfiguration.columnSeparator) !== null
                && getObjForValue(configContext.configGlobal.decimalSeparators, interfaceConfiguration.decimalSeparator) !== null
                && getObjForValue(configContext.configGlobal.thousandSeparators, interfaceConfiguration.thousandSeparator) !== null
                && interfaceConfiguration.startLine >= 0
                && getObjForValue(configContext.configGlobal.priceUnits, interfaceConfiguration.priceUnit) !== null
                && (interfaceConfiguration.columnSeparator === window.enumColumnSeparator.Custom ? interfaceConfiguration.customColumnSeparator : true);
        }
        else {
            return getObjForValue(configContext.configGlobal.columnSeparators, interfaceConfiguration.columnSeparator) !== null
                && getObjForValue(configContext.configGlobal.decimalSeparators, interfaceConfiguration.decimalSeparator) !== null
                && getObjForValue(configContext.configGlobal.thousandSeparators, interfaceConfiguration.thousandSeparator) !== null;
        }
    };

    const getObjForValue = (date, value) => {
        return date.filter(x => x.value === value)[0];
    };

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <ExpansionPanel
                                title={intl.formatMessage({ id: 'InterfaceConfiguration.Configuration' }) + " - " + (params.interfaceConfiguration?.configurationName ?? "")}
                                expandIcon="fa fa-angle-down"
                                collapseIcon="fa fa-angle-up"
                                expanded={expanded}
                                onAction={(e) => setExpanded(!e.expanded)}
                                className="expansion-panel-ic"
                            >
                                <Reveal>
                                    {expanded && (
                                        <ExpansionPanelContent>

                                            <Row>
                                                <Col xs="3">
                                                    <div className="m-3">
                                                        <Row>
                                                            <Label><FormattedMessage id='InterfaceConfiguration.ColumnSeparator' /></Label>
                                                        </Row>
                                                        <Row>
                                                            <DropDownList
                                                                id={"columnSeparator"}
                                                                name={"columnSeparator"}
                                                                value={getObjForValue(configContext.configGlobal.columnSeparators, interfaceConfiguration.columnSeparator)}
                                                                textField={"text"}
                                                                dataItemKey={"value"}
                                                                data={configContext.configGlobal.columnSeparators}
                                                                onChange={handleValueChange}
                                                            />
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="m-3">
                                                        <Row>
                                                            <Label><FormattedMessage id='InterfaceConfiguration.DecimalSeparator' /></Label>
                                                        </Row>
                                                        <Row>
                                                            <DropDownList
                                                                id={"decimalSeparator"}
                                                                name={"decimalSeparator"}
                                                                value={getObjForValue(configContext.configGlobal.decimalSeparators, interfaceConfiguration.decimalSeparator)}
                                                                textField={"text"}
                                                                dataItemKey={"value"}
                                                                data={configContext.configGlobal.decimalSeparators}
                                                                onChange={handleValueChange}
                                                            />
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="m-3">
                                                        <Row>
                                                            <Label><FormattedMessage id='InterfaceConfiguration.ThousandSeparator' /></Label>
                                                        </Row>
                                                        <Row>
                                                            <DropDownList
                                                                id={"thousandSeparator"}
                                                                name={"thousandSeparator"}
                                                                value={getObjForValue(configContext.configGlobal.thousandSeparators, interfaceConfiguration.thousandSeparator)}
                                                                textField={"text"}
                                                                dataItemKey={"value"}
                                                                data={configContext.configGlobal.thousandSeparators}
                                                                onChange={handleValueChange}
                                                            />
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="m-3">
                                                        <Row>
                                                            <Label><FormattedMessage id='InterfaceConfiguration.HeadingEnabled' /></Label>
                                                        </Row>
                                                        <Row>
                                                            <Switch
                                                                id={"headingEnabled"}
                                                                name={"headingEnabled"}
                                                                checked={interfaceConfiguration.headingEnabled}
                                                                onLabel={intl.formatMessage({ id: 'Control.Yes' })}
                                                                offLabel={intl.formatMessage({ id: 'Control.No' })}
                                                                onChange={handleValueChange}
                                                            />
                                                        </Row>
                                                    </div>

                                                </Col>

                                            </Row>


                                            {(params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData
                                                || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities)
                                                &&
                                                <Row>
                                                    {
                                                        interfaceConfiguration.columnSeparator === window.enumColumnSeparator.Custom &&
                                                        <Col xs="3">
                                                            <div className="m-3">
                                                                <Row>
                                                                    <Label><FormattedMessage id='InterfaceConfiguration.CustomColumnSeparator' /></Label>
                                                                </Row>
                                                                <Row>
                                                                    <div className="k-form-field">
                                                                        <Input
                                                                            id={"customColumnSeparator"}
                                                                            name={"customColumnSeparator"}
                                                                            maxLength={1}
                                                                            value={interfaceConfiguration.customColumnSeparator ?? ""}
                                                                            onChange={handleValueChange}
                                                                        />
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    }
                                                    <Col xs="3">
                                                        <div className="m-3">
                                                            <Row>
                                                                <Label><FormattedMessage id='InterfaceConfiguration.StartLine' /></Label>
                                                            </Row>
                                                            <Row>
                                                                <div className="k-form-field">
                                                                    <NumericTextBox
                                                                        id={"startLine"}
                                                                        name={"startLine"}
                                                                        min={1}
                                                                        max={1000}
                                                                        value={interfaceConfiguration.startLine}
                                                                        onChange={handleValueChange}
                                                                    />
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                    <Col xs="3">
                                                        <div className="m-3">
                                                            <Row>
                                                                <Label><FormattedMessage id='InterfaceConfiguration.PriceUnit' /></Label>
                                                            </Row>
                                                            <Row>
                                                                <DropDownList
                                                                    id={"priceUnit"}
                                                                    name={"priceUnit"}
                                                                    value={getObjForValue(configContext.configGlobal.priceUnits, interfaceConfiguration.priceUnit)}
                                                                    textField={"text"}
                                                                    dataItemKey={"value"}
                                                                    data={configContext.configGlobal.priceUnits}
                                                                    onChange={handleValueChange}
                                                                />
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                    <Col xs="3">
                                                        <div className="m-3">
                                                            <Row>
                                                                <Label><FormattedMessage id='InterfaceConfiguration.PriceIsValue' /></Label>
                                                            </Row>
                                                            <Row>
                                                                <Switch
                                                                    id={"priceEqualValue"}
                                                                    name={"priceEqualValue"}
                                                                    checked={interfaceConfiguration.priceEqualValue}
                                                                    onLabel={intl.formatMessage({ id: 'Control.Yes' })}
                                                                    offLabel={intl.formatMessage({ id: 'Control.No' })}
                                                                    onChange={handleValueChange}
                                                                />
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            {(params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfAccountingData
                                                || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfCountItems
                                                || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfItemTable)
                                                &&

                                                <Row>
                                                    {
                                                        interfaceConfiguration.columnSeparator === window.enumColumnSeparator.Custom &&
                                                        <Col xs="3">
                                                            <div className="m-3">
                                                                <Row>
                                                                    <Label><FormattedMessage id='InterfaceConfiguration.CustomColumnSeparator' /></Label>
                                                                </Row>
                                                                <Row>
                                                                    <div className="k-form-field">
                                                                        <Input
                                                                            id={"customColumnSeparator"}
                                                                            name={"customColumnSeparator"}
                                                                            maxLength={1}
                                                                            value={interfaceConfiguration.customColumnSeparator}
                                                                            onChange={handleValueChange}
                                                                        />
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    }
                                                    <Col xs="3">
                                                        <div className="m-3">
                                                            <Row>
                                                                <Label><FormattedMessage id='InterfaceConfiguration.ExportPositionAndPageNumbers' /></Label>
                                                            </Row>
                                                            <Row>
                                                                <Switch
                                                                    id={"exportPositionAndPageNumbers"}
                                                                    name={"exportPositionAndPageNumbers"}
                                                                    checked={interfaceConfiguration.exportPositionAndPageNumbers}
                                                                    onLabel={intl.formatMessage({ id: 'Control.Yes' })}
                                                                    offLabel={intl.formatMessage({ id: 'Control.No' })}
                                                                    onChange={handleValueChange}
                                                                />
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                    <Col xs="3">
                                                        <div className="m-3">
                                                            <Row>
                                                                <Label><FormattedMessage id='InterfaceConfiguration.LeadingZeros' /></Label>
                                                            </Row>
                                                            <Row>
                                                                <Switch
                                                                    id={"leadingZeros"}
                                                                    name={"leadingZeros"}
                                                                    checked={interfaceConfiguration.leadingZeros}
                                                                    onLabel={intl.formatMessage({ id: 'Control.Yes' })}
                                                                    offLabel={intl.formatMessage({ id: 'Control.No' })}
                                                                    onChange={handleValueChange}
                                                                />
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                    {(params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfCountItems
                                                        || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfAccountingData) &&
                                                        <Col xs="3">
                                                            <div className="m-3">
                                                                <Row>
                                                                    <Label><FormattedMessage id='InterfaceConfiguration.NumberOfPositionsOnPage' /></Label>
                                                                </Row>
                                                                <Row>
                                                                    <div className="k-form-field">
                                                                        <NumericTextBox
                                                                            id={"numberOfPositionsOnPage"}
                                                                            name={"numberOfPositionsOnPage"}
                                                                            min={1}
                                                                            max={50}
                                                                            value={interfaceConfiguration.numberOfPositionsOnPage}
                                                                            onChange={handleValueChange}
                                                                        />
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    }

                                                </Row>

                                            }
                                        </ExpansionPanelContent>
                                    )}
                                </Reveal>
                            </ExpansionPanel>
                        </CardBody>
                    </Card>


                </Col>
            </Row>
        </div>
    );
};

InterfaceConfigurationParameters.propTypes = {
    params: PropTypes.object,
};

export default InterfaceConfigurationParameters;
