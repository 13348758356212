import React, { useEffect, useCallback } from 'react';
import { Col, Row, CardHeader, Card, CardBody, Button, Label } from 'reactstrap';
import { DragAndDrop } from "@progress/kendo-react-common";
import { DraggableButton } from './DraggableButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { StackLayout } from '@progress/kendo-react-layout';
import EditActionCell from './EditActionCell';
import { DropBoxCell } from './DropBoxCell';
import { Grid, GridNoRecords, GridColumn as Column } from '@progress/kendo-react-grid';
import { deepClone } from '../../../utils/jsonUtils';
import { ConfigContext } from './ConfigContext';
import ConfirmDialog from '../../Shared/ConfirmDialog';
import ReactTooltip from 'react-tooltip';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import OptionSelectorDialog from '../../Shared/OptionSelectorDialog';
import HeaderEditForm from './HeaderEditForm';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { Upload } from '@progress/kendo-react-upload';
import { SwitchCell } from './SwitchCell';
import { PageBreakCell } from './PageBreakCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { useSelector } from 'react-redux';
import { RadioButton } from '@progress/kendo-react-inputs';
import { orderBy } from "@progress/kendo-data-query";
import { CellRender } from '../../Shared/CellRender';
import { getFSort } from './ConfigEnums';
import PropTypes from 'prop-types';

const editField = "inEdit";

const FILE_SLICE_SIZE_KB = 5;


export const ReorderContext = React.createContext({
    reorder: () => { },
    dragStart: () => { },
});

const InterfaceConfigurationFieldMappings = ({ params }) => {
    const pageId = 'InterfaceConfigurationFieldMappings' + (params?.interfaceConfigParamsType ?? "");
    const intl = useIntl();
    const configContext = React.useContext(ConfigContext);
    const [disable, setDisable] = React.useState(false);
    const [editItem, setEditItem] = React.useState(null);
    const [dragElement, setDragElement] = React.useState(null);
    const dispatchNotification = useNotification();
    const [headers, setHeaders] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [originalData, setOriginalData] = React.useState([]);
    const [deleteConfVisibile, setDeleteConfVisibile] = React.useState(false);
    const [deleteHeaderVisibile, setDeleteHeaderVisibile] = React.useState(false);
    const [headerForDelete, setHeaderForDelete] = React.useState({});
    const [itemForDelete, setItemForDelete] = React.useState({});
    const [isAddOptionsVisible, setIsAddOptionsVisible] = React.useState(false);
    const [isAHederEditFormVisible, setIsAHederEditFormVisible] = React.useState(false);
    const userProfile = useSelector(state => state.profile.profile);
    const [fileLoaded, setFileLoaded] = React.useState(false);
    const [sortOnPosition, setSortOnPosition] = React.useState(true);

    useEffect(() => {
        if (params.state.interfaceConfigurationFieldMappings) {
            let isImport = params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities;
            let modifiedData = deepClone(params.state.interfaceConfigurationFieldMappings);
            modifiedData.forEach(e => e.showClose = isImport);

            let newSort = [{ ...sort[0], field: "position" }];
            let sorted = getSortedData(modifiedData, newSort);

            let lastColumnOrder = 0
            let orderIsOnPosition = true;
            sorted.forEach(e => {
                if (lastColumnOrder >= e.columnOrder) {
                    orderIsOnPosition = false;
                }
                lastColumnOrder = e.columnOrder
            });

            if (!orderIsOnPosition) {
                newSort = [{ ...sort[0], field: "fSort" }];
                if (sortOnPosition) {
                    setSortOnPosition(!sortOnPosition)
                }
                sorted = getSortedData(modifiedData, newSort);
            }

            setOriginalData(sorted);
            setSort(newSort)
        }
    }, [params.state.interfaceConfigurationFieldMappings]);

    useEffect(() => {
        if (originalData) {
            setCurrentState();
        }
        setDisable(false);
    }, [originalData]);

    useEffect(() => {
        if (params.isInterfaceConfigurationLoaded) {
            adjustPositions();
            manageValidityAndChanged();
        }
    }, [configContext.configGlobal.modifiedParamsState, params.isInterfaceConfigurationLoaded]);

    useEffect(() => {
        let dataToChange = data;
        translateFields(dataToChange, window.enumFields.StratumNumber, intl.formatMessage({ id: 'InterfaceConfiguration.Stratum' }));
        translateFields(dataToChange, window.enumFields.QuantityDifference, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceQuantity' }));
        translateFields(dataToChange, window.enumFields.DummyT1, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceQuantitySign' }));
        translateFields(dataToChange, window.enumFields.ValueDifference, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceValue' }));
        translateFields(dataToChange, window.enumFields.DummyT2, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceValueSign' }));
        setData(dataToChange);

    }, [userProfile.userLanguage]);

    const translateFields = (data, fId, columnName) => {
        let element = data.find((dest) => dest.fId === fId)
        if (element) {
            element.originalColumnName = columnName;
            element.customColumnName = columnName;
            element.reportColumnName = columnName;
        }
    }

    useEffect(() => {
        if (params.isInterfaceConfigurationLoaded) {
            manageValidityAndChanged();
        }
    }, [data, params.isInterfaceConfigurationLoaded]);

    const minimumValidPosition = useCallback(() => {
        return configContext.configGlobal?.modifiedParamsState?.exportPositionAndPageNumbers ? 3 : 1;
    }, [configContext.configGlobal?.modifiedParamsState?.exportPositionAndPageNumbers]);

    const manageValidityAndChanged = () => {
        if (!(originalData && data)) {
            return;
        }
        let isChanged = getIsChanged();
        params.setIsMappingsChanged(isChanged);
        if (configContext.configGlobal.selectedTabType !== params.interfaceConfigParamsType)
            return;
        let isValid = getIsValid();
        params.setIsMappingValid(isValid);
        if (isValid) {
            params.setInterfaceConfigurationFieldMappings(deepClone(data));
        }
    };

    const getIsChanged = () => {
        removeNotification();
        if (originalData.length !== data.length)
            return true;
        for (let i = 0; i < data.length; i++) {
            let current = data[i];
            let original = originalData[i];
            if (isMappingChanged(current, original)) {
                return true;
            }
        }
        return false;
    };

    const isMappingChanged = (current, original) => {
        return current.customColumnName !== original.customColumnName
            || current.originalColumnName !== original.originalColumnName
            || current.reportColumnName !== original.reportColumnName
            || current.position !== original.position
            || current.lenght !== original.lenght
            || current.decimalPlaces !== original.decimalPlaces
            || current.reportColumnName !== original.reportColumnName
            || current.usedByImportExport !== original.usedByImportExport
            || current.isNotNullOnImport !== original.isNotNullOnImport
            || current.noOfCharactersUsedForPageBreakOnCountList !== original.noOfCharactersUsedForPageBreakOnCountList
            || current.sortPositionOnCountList !== original.sortPositionOnCountList
            || current.noOfCharactersUsedForPageBreakOnDifferenceList !== original.noOfCharactersUsedForPageBreakOnDifferenceList
            || current.sortPositionOnDifferenceList !== original.sortPositionOnDifferenceList;
    };

    const adjustPositions = useCallback(() => {
        if ((data?.length ?? 0) > 0) {
            const minPosInMapping = data.reduce((min, obj) => {
                return obj.position < min ? obj.position : min;
            }, data?.[0]?.position ?? 0);

            const minValidPos = minimumValidPosition();

            if (minPosInMapping !== minValidPos) {
                setData(prevData => prevData.map(mapping => ({ ...mapping, position: mapping.position + (minValidPos - minPosInMapping) })));
                setOriginalData(prevData => prevData.map(mapping => ({ ...mapping, position: mapping.position + (minValidPos - minPosInMapping) })));
            }
        }
    }, [minimumValidPosition, data]);

    const getIsValid = () => {
        for (const element of data) {
            let item = element;
            let isValid = isDataItemValid(item);
            if (!isValid) {
                return false;
            }
        }

        let dataToValidate = data;
        if (params.interfaceConfigParamsType !== window.enumInterfaceConfigParamsType.ImportOfInventoryData) {
            dataToValidate = data.filter((item) => item.usedByImportExport);
        }

        if (!validateImportOfInventoryData(dataToValidate)) {
            return false;
        }

        let distinctPositions = dataToValidate.map(p => p.position).reduce((distinct, item) =>
            !distinct.includes(item) ? [...distinct, item] : distinct, []);

        if (distinctPositions.length !== dataToValidate.length) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="InterfaceConfiguration.AllColumnNumbersHaveToBeDifferent" />
            });
            return false;
        }

        const minpos = minimumValidPosition();
        if (distinctPositions.some((item) => item < minpos)) {
            const message = configContext.configGlobal?.modifiedParamsState?.exportPositionAndPageNumbers
                ? <FormattedMessage id="InterfaceConfiguration.CoumnNumbersReserved" />
                : <FormattedMessage id="InterfaceConfiguration.AllColumnNumbersHaveToBeBiggerThanZero" />

            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: message
            });
            return false;
        }

        if (!validateImportOfCountQuantities(dataToValidate)) {
            return false;
        }

        if (!validateExportOfCountItems(dataToValidate)) {
            return false;
        }

        return validateExportOfAccountingData(dataToValidate);
    };

    const validateImportOfInventoryData = (dataToValidate) => {
        if (params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData) {
            const existsIdentField = dataToValidate.some(x => x.fId >= window.enumFields.Ident1 && x.fId <= window.enumFields.Ident15);
            if (!existsIdentField) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.UniqueIdMustBeMapped" />
                });
                return false;
            }

            const existsQuantityField = dataToValidate.some(x => x.fId === window.enumFields.Quantity);
            if (!existsQuantityField) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.QuantityMustBeMapped" />
                });
                return false;
            }
        }

        return true;
    };

    const validateExportOfCountItems = (dataToValidate) => {
        if (params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfCountItems) {
            if (dataToValidate.some(x => x.sortPositionOnCountList != null && x.sortPositionOnCountList <= 0)) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.SortOrderMustBeGreaterThanZero" />
                });
                return false;
            }

            const filledSortPositions = dataToValidate.filter(x => x.sortPositionOnCountList > 0);
            const distinctSortPositions = filledSortPositions.map(x => x.sortPositionOnCountList).reduce((distinct, item) =>
                !distinct.includes(item) ? [...distinct, item] : distinct, []);

            if (distinctSortPositions.length !== filledSortPositions.length) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.SortOrdersMustBeDistinct" />
                });
                return false;
            }

            const pageBreakOnCountListCount = dataToValidate.filter(x => x.noOfCharactersUsedForPageBreakOnCountList > 0).length;

            if (pageBreakOnCountListCount > 1) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.ChangePageAfterXCharactersAllowedForSingleColumn" />
                });
                return false;
            }
        }

        return true;
    };

    const validateExportOfAccountingData = (dataToValidate) => {
        if (params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfAccountingData) {
            if (dataToValidate.some(x => x.sortPositionOnDifferenceList != null && x.sortPositionOnDifferenceList <= 0)) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.SortOrderMustBeGreaterThanZero" />
                });
                return false;
            }

            const filledSortPositions = dataToValidate.filter(x => x.sortPositionOnDifferenceList > 0);
            const distinctSortPositions = filledSortPositions.map(x => x.sortPositionOnDifferenceList).reduce((distinct, item) =>
                !distinct.includes(item) ? [...distinct, item] : distinct, []);

            if (distinctSortPositions.length !== filledSortPositions.length) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.SortOrdersMustBeDistinct" />
                });
                return false;
            }

            const pageBreakOnDifferenceListCount = dataToValidate.filter(x => x.noOfCharactersUsedForPageBreakOnDifferenceList > 0).length;

            if (pageBreakOnDifferenceListCount > 1) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.ChangePageAfterXCharactersAllowedForSingleColumn" />
                });
                return false;
            }
        }

        return true;
    };

    const validateImportOfCountQuantities = (dataToValidate) => {
        if (params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities) {
            let initialDataIdent = params.initialData.interfaceConfigurationFieldMappings.filter(x => x.fId <= window.enumFields.Ident15).map((item) => item.originalColumnName.toLowerCase().trim());

            if (dataToValidate.filter(x => initialDataIdent.some((item) => item === x.originalColumnName.toLowerCase().trim())).length < initialDataIdent.length) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.AllIdentFieldsHaveToBeSelected" />
                });
                return false;
            }

            if (dataToValidate.filter((item) => item.fId === window.enumFields.ActualQuantity).length === 0) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.ActualQuantityHasToBeSelected" />
                });
                return false;
            }
        }

        return true;
    };

    useEffect(() => {
        setCurrentState();
        setDisable(false);
    }, [params.doReset]);

    const setCurrentState = () => {
        if (params.state && originalData) {
            let newdata = deepClone(originalData)
            let sorted = getSortedData(newdata, sort);
            setData(sorted);
        }
    }

    useEffect(() => {
        if (params.state && params.headers) {
            let headers = deepClone(params.headers)
            headers.forEach((item) => {
                if (data.some(x => x.originalColumnName === item.ColumnName)) {
                    item.selected = true;
                }
            });
            setHeaders(headers);
        }
    }, [params.headers, data]);

    const onInventoryDataFileLoaded = () => {
        let columnNames = params.headers.map(x => (x.ColumnName.toLowerCase()));
        let filteredData = data.filter(x => x.originalColumnName).filter(x => columnNames.includes(x.originalColumnName.toLowerCase()));
        filteredData.forEach((item) => {
            let filteredHeader = params.headers.filter(x => x.ColumnName.toLowerCase() === item.originalColumnName.toLowerCase());
            if (filteredHeader.length === 1) {
                item.position = filteredHeader[0].ColumnNo
            }
        });
        setData(filteredData);
    }

    const onCountQuantitiesFileLoaded = () => {
        data.forEach((item) => {
            let filteredHeader = params.headers.filter(x => x.ColumnName.toLowerCase() === (item.originalColumnName ? item.originalColumnName.toLowerCase() : ""));
            if (filteredHeader.length === 1) {
                item.position = filteredHeader[0].ColumnNo
            }
            else {
                item.originalColumnName = null;
            }
        });
        let newData = data;
        setData(newData);
    }

    useEffect(() => {
        if (fileLoaded) {
            if (params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData) {
                onInventoryDataFileLoaded();
            }
            else {
                onCountQuantitiesFileLoaded();
            }
            setFileLoaded(false);
        }
    }, [fileLoaded]);

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const onDragEnd = React.useCallback((element) => {
        setDragElement(null);
    }, []);

    const onDragStart = React.useCallback((element) => {
        setDragElement(element)
    }, []);

    const removeHeaderItemChanged = (event) => {
        let header = event.dataItem.originalColumnName;

        const newData = data.map((item) =>
            item.fId === event.dataItem.fId
                ? {
                    ...item,
                    originalColumnName: "",
                    changed: true,
                    position: 0
                }
                : item
        );

        let sorted = getSortedData(newData, sort);
        setData(sorted);

        let headersList = headers.map((item) =>
            item.ColumnName === header
                ? { ...item, selected: false }
                : item
        );
        configContext.dispatchConfigGlobal({
            type: params.dispatchHeadetType,
            payload: headersList
        });
    }

    const handleItemChangeNewData = (event) => {
        const newData = data.map((item) =>
            item.fId === event.dataItem.fId
                ? getChangedElement(event, item)
                : item
        );

        if (params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData) {
            setData(newData);
        }
        else if (event.field === "originalColumnName" && dragElement) {
            let sorted = getSortedData(newData, sort);
            setData(sorted);
            setDisable(false);
        }
        else {
            setData(newData);
        }

        if (event.field === "originalColumnName" && dragElement) {
            let headersList = headers.map((item) =>
                item.ColumnName === dragElement.ColumnName
                    ? { ...item, selected: true }
                    : item
            );

            setHeaders(headersList);
        }
    }

    const itemChange = (event) => {
        if (event.field === "customColumnName") {
            if (event.value.length > 50) {
                return;
            }
        }
        if (event.field === "reportColumnName") {
            if (event.value.length > 25) {
                return;
            }
        }

        if (event.field === "removeHeader") {
            removeHeaderItemChanged(event);
        }
        else {
            if (!event.dataItem.originalColumnName && event.field === "position") {
                return;
            }

            handleItemChangeNewData(event);
        }
    };

    const getChangedElement = (event, item) => {
        if (event.field === "originalColumnName") {
            let element = { ...item, [event.field]: (dragElement ? dragElement.ColumnName : ""), changed: true };
            let columnName = dragElement ? dragElement.ColumnName : ""
            element.customColumnName = (element.customColumnName && element.customColumnName.length > 0) ? element.customColumnName : columnName;
            element.reportColumnName = (element.reportColumnName && element.reportColumnName.length > 0) ? element.reportColumnName : columnName;
            element.position = dragElement ? dragElement.ColumnNo : 0
            return element;
        }
        else {
            return { ...item, [event.field || ""]: event.value, changed: true }
        }

    }

    const enterEdit = (dataItem) => {
        dataItem.changed = false;
        setDisable(true);
        dataItem.inEdit = true;
        dataItem.skipBlur = true;
        setEditItem(dataItem);
        setData(
            data.map((item) =>
                item.fId === dataItem.fId ? { ...item, inEdit: true, changed: false, usedByImportExport: true } : item
            )
        );
    };

    const remove = (dataItem) => {
        setDeleteConfVisibile(true)
        setItemForDelete(dataItem);
        setDisable(false);
    }
    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
        setDisable(false);
        setEditItem(null);
    };

    const isDataItemValid = (item, showError) => {
        if (!item.fId || item.fId <= 0)
            return false;
        if (!item.customColumnName || item.customColumnName.length === 0) {
            if (showError)
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.ColumnNameReq" />
                })
            return false;
        }
        if (!item.originalColumnName || item.originalColumnName.length === 0) {
            if (showError)
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.MapToReq" />
                })
            return false;
        }
        if (!item.reportColumnName || item.reportColumnName.length === 0) {
            if (showError)
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.ReportNameReq" />
                })
            return false;
        }
        return true;
    };

    const update = (dataItem) => {
        setDisable(false);
        removeNotification();
        if (!isDataItemValid(dataItem, true)) {
            return;
        }
        dataItem.inEdit = false;
        let temp = data.map(item => {
            if (item.fId === dataItem.fId)
                return dataItem;
            else
                return item;
        })

        let sorted = getSortedData(temp, sort);
        setData(sorted);

        setEditItem(null);
        setDisable(false);
    }



    const add = (newItem) => {
        removeNotification();

        if (!isDataItemValid(newItem, true)) {
            return;
        }

        newItem.inEdit = false;
        newItem.fakeId = 1;
        setEditItem(null);

        setDisable(false);
        const newData = data.map((item) =>
            item.fId === newItem.fId ? newItem : item
        );
        let sorted = getSortedData(newData, sort);
        setData(sorted);
    }

    const cancel = (_dataItem) => {
        setData(prevData => prevData.map((item) =>
            item.fId === editItem.fId ? { ...editItem, inEdit: false } : item
        ));
        setEditItem(null);
        setDisable(false);
    }

    const EditItem = (pr) => (
        <EditActionCell
            {...pr}
            edit={enterEdit}
            remove={remove}
            discard={discard}
            update={update}
            add={add}
            cancel={cancel}
            editField={editField}
            disabled={disable}
            idPropName={"fakeId"}
            showDelete={params?.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData}
        />
    );

    const handleConfirmDialogClose = (result) => {
        if (result) {
            onConfirmDelete()
        }
        else {
            onCancelDelete()
        }
    }
    const onCancelDelete = () => {
        setDeleteConfVisibile(false);
    }

    const onConfirmDelete = () => {
        setDeleteConfVisibile(false);
        removeNotification();

        let temp = data.filter(x => x.fId !== itemForDelete.fId)
        setData(temp);
    };

    const generateNewItem = (fId) => {
        return {
            fakeId: -1,
            interfaceConfigurationParametersId: params?.state?.interfaceConfigurationParametersId ?? -1,
            fId: fId,
            fSort: getFSort(fId),
            originalColumnName: '',
            reportColumnName: '',
            position: 0,
            lenght: 0,
            decimalPlaces: 0,
            isNotNullOnImport: false,
            usedByImportExport: true,
            skipBlur: params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData
        };
    }

    const handleAddICPButton = (_event) => {
        setIsAddOptionsVisible(true);
    }

    const setFocusInEditedGridRow = () => {
        setTimeout(() => {
            let grids = document.getElementsByClassName('k-grid');
            if (grids.length > 0) {
                let grid = grids[0];
                let gridRows = grid.getElementsByClassName('k-grid-edit-row');
                if (gridRows.length > 0) {
                    let insertedRow = gridRows[0];
                    let inputs = insertedRow.getElementsByClassName('k-textbox')
                    if (inputs.length > 0) {
                        inputs[0].focus();
                    }
                }
            }
        });
    }

    const handleOptionSelectorDialogClose = (optionValue) => {
        setIsAddOptionsVisible(false);
        if (!optionValue)
            return;

        let fId = optionValue;
        const maxLimit = (optionValue === window.enumFields.Ident1)
            ? window.enumFields.Ident15
            : window.enumFields.AdditionalField15;
        switch (optionValue) {
            case window.enumFields.Ident1:
            case window.enumFields.AdditionalField1:
                for (let tempId = fId; tempId <= maxLimit + 1; tempId++) {
                    if (tempId > maxLimit) {
                        return;
                    }
                    if (!data.some((item) => item.fId === tempId)) {
                        fId = tempId;
                        break;
                    }
                }
                break;
            case window.enumFields.Quantity:
            case window.enumFields.SinglePrice:
            case window.enumFields.PriceUnit:
            case window.enumFields.QuantityUnit:
            case window.enumFields.ItemName:
            case window.enumFields.ActualQuantity:
            case window.enumFields.CategoryMark:
            case window.enumFields.QuantitySign:
            case window.enumFields.PriceSign:
                break;
            default:
                return;
        }

        const newDataItem = generateNewItem(fId);
        newDataItem.inEdit = true;
        newDataItem.usedByImportExport = true;

        setData([newDataItem, ...data]);
        setDisable(true);

        setFocusInEditedGridRow();
    };

    const getFieldTypes = () => {
        let result = [];

        if (configContext.configGlobal.fieldTypes) {
            let fieldType = deepClone(configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.Ident1))
            let existingCount = data.filter(x => x.fId <= window.enumFields.Ident15) ? data.filter(x => x.fId <= window.enumFields.Ident15).length : 0
            fieldType.disabled = existingCount >= window.enumFields.Ident15;
            fieldType.text = fieldType.text + " (" + (window.enumFields.Ident15 - existingCount) + ")"
            result.push(fieldType);

            fieldType = configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.Quantity)
            fieldType.disabled = data.filter(x => x.fId === window.enumFields.Quantity).length > 0;
            result.push(fieldType);

            fieldType = configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.ActualQuantity)
            fieldType.disabled = data.filter(x => x.fId === window.enumFields.ActualQuantity).length > 0;
            result.push(fieldType);

            fieldType = configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.SinglePrice)
            fieldType.disabled = data.filter(x => x.fId === window.enumFields.SinglePrice).length > 0;
            result.push(fieldType);

            fieldType = configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.PriceUnit)
            fieldType.disabled = data.filter(x => x.fId === window.enumFields.PriceUnit).length > 0;
            result.push(fieldType);

            fieldType = configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.QuantityUnit)
            fieldType.disabled = data.filter(x => x.fId === window.enumFields.QuantityUnit).length > 0;
            result.push(fieldType);

            fieldType = configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.ItemName)
            fieldType.disabled = data.filter(x => x.fId === window.enumFields.ItemName).length > 0;
            result.push(fieldType);

            fieldType = configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.CategoryMark)
            fieldType.disabled = data.filter(x => x.fId === window.enumFields.CategoryMark).length > 0;
            result.push(fieldType);

            if (configContext.configGlobal.modifiedParamsState && configContext.configGlobal.modifiedParamsState.columnSeparator === window.enumColumnSeparator.None) {
                fieldType = configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.QuantitySign)
                fieldType.disabled = data.filter(x => x.fId === window.enumFields.QuantitySign).length > 0;
                result.push(fieldType);

                fieldType = configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.PriceSign)
                fieldType.disabled = data.filter(x => x.fId === window.enumFields.PriceSign).length > 0;
                result.push(fieldType);
            }


            fieldType = deepClone(configContext.configGlobal.fieldTypes.find(x => x.value === window.enumFields.AdditionalField1));
            existingCount = data.filter(x => x.fId >= window.enumFields.AdditionalField1 && x.fId <= window.enumFields.AdditionalField15) ? data.filter(x => x.fId >= window.enumFields.AdditionalField1 && x.fId <= window.enumFields.AdditionalField15).length : 0
            fieldType.disabled = existingCount >= window.enumFields.AdditionalField15 - window.enumFields.AdditionalField1 + 1;
            fieldType.text = fieldType.text + " (" + (window.enumFields.AdditionalField15 - window.enumFields.AdditionalField1 - existingCount + 1) + ")"
            result.push(fieldType);

        }
        return result;
    }

    const [currentHeder, setCurrentHeder] = React.useState(null);

    const handleEditHeaderDialogClose = (item) => {
        setIsAHederEditFormVisible(false);
        if (item) {
            let headerIndex = headers.findIndex((x) => x.ColumnNo === item.ColumnNo);
            if (headerIndex >= 0) {
                headers[headerIndex].ColumnName = item.ColumnName;
                configContext.dispatchConfigGlobal({
                    type: params.dispatchHeadetType,
                    payload: headers
                });
            }
            else {
                let newHeaders = [...headers, item]
                configContext.dispatchConfigGlobal({
                    type: params.dispatchHeadetType,
                    payload: newHeaders
                });
            }
        }
    }

    const handleAddHeaderButton = () => {
        setCurrentHeder(
            {
                ColumnName: "",
                selected: false,
                ColumnNo: Math.max(...headers.map(x => x.ColumnNo)) + 1
            }
        );
        setIsAHederEditFormVisible(true);
    }

    const onHeaderDoubleClick = (item) => {
        setCurrentHeder(item);
        setIsAHederEditFormVisible(true);
    }

    const onRemoveHeader = (item) => {
        setHeaderForDelete(item);
        setDeleteHeaderVisibile(true);

    }

    const handleHeaderDialogClose = (result) => {
        if (result) {
            setDeleteHeaderVisibile(false);
            removeNotification();

            let headerIndex = headers.findIndex((x) => x.ColumnNo === headerForDelete.ColumnNo);
            headers.splice(headerIndex, 1);
            if (headerIndex >= 0) {
                configContext.dispatchConfigGlobal({
                    type: params.dispatchHeadetType,
                    payload: headers
                });
            }
        }
        else {
            setDeleteHeaderVisibile(false);
        }
    }

    const handleFileSelected = e => {
        let target = e.target;
        if (target.state.files.length > 0) {
            let fr = new FileReader();
            fr.onloadend = function (evt) {

                if (evt.target.readyState === FileReader.DONE) {
                    let currentSeparator = configContext.configGlobal.columnSeparators.find(x => x.value === configContext.configGlobal.currentColumnSeparator).textValue;
                    if (configContext.configGlobal.currentColumnSeparator === window.enumColumnSeparator.Custom) {
                        currentSeparator = configContext.configGlobal.customColumnSeparator
                    }

                    let columnHeaders = evt.target.result.split('\n')[0].split(currentSeparator).map((headerText, index) => ({ ColumnName: headerText.replace('\r', '').trim(), ColumnNo: index + 1, selected: false }));

                    configContext.dispatchConfigGlobal({
                        type: params.dispatchHeadetType,
                        payload: columnHeaders
                    });
                    setFileLoaded(true);
                    setDisable(false);
                }
            };
            let blob = target.state.files[0].getRawFile().slice(0, 1024 * FILE_SLICE_SIZE_KB);
            fr.readAsText(blob);
        }
    };


    const [sort, setSort] = React.useState([
        {
            field: sortOnPosition ? "position" : "fSort",
            dir: "asc",
        },
    ]);

    const handleSortChange = () => {
        setSortOnPosition(!sortOnPosition);
        let newSort = [{ ...sort[0], field: !sortOnPosition ? "position" : "fSort" }];
        setSort(newSort)
        let sorted = getSortedData(data, newSort);
        setData(sorted);
    };

    const getSortedData = (dataToSort, sort) => {
        return orderBy(dataToSort, sort);
    };

    const sortData = () => {
        let sorted = getSortedData(data, sort);
        setData(sorted);
    }

    const cellLostFocus = () => {
        sortData();
    };

    const customCellRender = (td, props) => (
        <CellRender
            originalProps={props}
            td={td}
            cellLostFocus={cellLostFocus}
            editField={editField}
        />
    );

    return (

        <div className="content">
            <HeaderEditForm visible={isAHederEditFormVisible}
                name='reporting-redraw-option'
                onClose={handleEditHeaderDialogClose}
                negative={<FormattedMessage id="Control.Cancel" />}
                positive={<FormattedMessage id="Control.Confirm" />}
                item={currentHeder}
                dispatchNotification={dispatchNotification}
                otherItems={headers}
            >
            </HeaderEditForm>
            <DragAndDrop>
                {(params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities) &&
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row >
                                        <Col>
                                            <h3>
                                                <FormattedMessage id='InterfaceConfiguration.ImportedHeaders' />
                                                <ReactTooltip id="headerAddTip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                                                <div className="float-right">
                                                    <Button color="primary" onClick={handleAddHeaderButton} data-tip={intl.formatMessage({ id: 'InterfaceConfiguration.AddHeader' })} data-for="headerAddTip">
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button>
                                                </div>
                                            </h3>
                                        </Col>
                                    </Row>

                                    {(params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities) &&
                                        <Row>
                                            <Col xs="5">
                                                <Row className="interface-content">
                                                    <Col xs="12">
                                                        <div className="mb-3, mt-3">
                                                            <Upload
                                                                batch={false}
                                                                multiple={false}
                                                                label={intl.formatMessage({ id: 'InterfaceConfiguration.UploadInventoryData' })}
                                                                defaultFiles={[]}
                                                                withCredentials={false}
                                                                autoUpload={false}
                                                                showActionButtons={false}
                                                                accept={'.csv, .dat, .txt'}
                                                                saveUrl={""}
                                                                removeUrl={""}
                                                                className="interface-upload"
                                                                onAdd={handleFileSelected}
                                                            />
                                                        </div>

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    }

                                    <Row className="m-3">

                                        <StackLayout className="drag-stack"
                                            orientation={"horizontal"}
                                            gap={10}
                                        >
                                            {
                                                headers.map(x => <DraggableButton className="btn btn-secondary" key={x.ColumnName + x.ColumnNo} params={{ column: x, onDragEnd: onDragEnd, onDragStart: onDragStart, onDoubleClick: onHeaderDoubleClick, showClose: true, onRemove: onRemoveHeader }} />)
                                            }
                                        </StackLayout>

                                    </Row>
                                </CardBody>
                                <Row className="m-3">
                                    <Label> <FormattedMessage id="InterfaceConfiguration.HeaderDoubleClickToEdit" /></Label>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs="12">
                        <Card>

                            <OptionSelectorDialog visible={isAddOptionsVisible}
                                name='reporting-redraw-option'
                                onClose={handleOptionSelectorDialogClose}
                                negative={<FormattedMessage id="Control.Cancel" />}
                                positive={<FormattedMessage id="Control.Confirm" />}
                                title={<FormattedMessage id="InterfaceConfiguration.SelectFieldType" />}
                                options={getFieldTypes()}
                            />

                            <ConfirmDialog
                                visible={deleteConfVisibile}
                                onClose={handleConfirmDialogClose}
                                negative={<FormattedMessage id='Control.Cancel' />}
                                positive={<FormattedMessage id='Control.Delete' />}
                                title={<FormattedMessage id='InterfaceConfiguration.ConfirmDeleteColumnMappingTitle' />}
                                detail={intl.formatMessage({ id: "InterfaceConfiguration.ConfirmDeleteColumnMappingText" }, { 'ColumnMapping': itemForDelete.customColumnName })}
                            />
                            <ConfirmDialog
                                visible={deleteHeaderVisibile}
                                onClose={handleHeaderDialogClose}
                                negative={<FormattedMessage id='Control.Cancel' />}
                                positive={<FormattedMessage id='Control.Delete' />}
                                title={<FormattedMessage id='InterfaceConfiguration.DeleteHeaderTitle' />}
                                detail={intl.formatMessage({ id: "InterfaceConfiguration.ConfirmHeaderDeleteText" }, { 'Name': headerForDelete.ColumnName })}
                            />
                            <CardHeader>
                                <h3>
                                    <FormattedMessage id='InterfaceConfiguration.ColumnMappings' />
                                    <ReactTooltip id="addColumnMappingTip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                                    <div className="float-right">
                                        {(params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData || params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities) &&
                                            <Button color="primary" disabled={disable} onClick={handleAddICPButton} data-tip={intl.formatMessage({ id: 'InterfaceConfiguration.AddMapping' })} data-for="addColumnMappingTip">
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        }
                                    </div>
                                </h3>
                                <Row>
                                    <div className="ml-3 m-1">
                                        <span className="individual-license-type"><FormattedMessage id='InterfaceConfiguration.Arrangement' /></span>
                                    </div>
                                    <div className="ml-2 m-1">
                                        <RadioButton
                                            className={"individual-license-radio"}
                                            name="SortOrder"
                                            value="position"
                                            onClick={handleSortChange}
                                            checked={sortOnPosition}
                                        />
                                        <span className="individual-license-type"><FormattedMessage id='InterfaceConfiguration.SortOnFile' /></span>
                                    </div>
                                    <div className="ml-3 m-1">
                                        <RadioButton
                                            className={"individual-license-radio"}
                                            name="SortOrder"
                                            value="fSort"
                                            onClick={handleSortChange}
                                            checked={!sortOnPosition}
                                        />
                                        <span className="individual-license-type"><FormattedMessage id='InterfaceConfiguration.SortOnDefault' /></span>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Grid
                                    data={data}
                                    key="fId"
                                    onItemChange={itemChange}
                                    editField={editField}
                                    sort={sort}
                                    cellRender={customCellRender}
                                    className="hide-sort"
                                >
                                    <GridNoRecords>
                                        <FormattedMessage id="InterfaceConfiguration.NoInterfaceConfiguration" />
                                    </GridNoRecords>
                                    {params.interfaceConfigParamsType !== window.enumInterfaceConfigParamsType.ImportOfInventoryData &&
                                        <Column field="usedByImportExport" width="50px" title=" " editor="boolean" />
                                    }
                                    <Column
                                        field="originalColumnName"
                                        width="500px"
                                        title={intl.formatMessage({ id: "InterfaceConfiguration.MapTo" })}
                                        cell={DropBoxCell}
                                    />
                                    {params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData &&
                                        <Column field="isNotNullOnImport" title={intl.formatMessage({ id: "InterfaceConfiguration.IsNotNullOnImport" })} cell={SwitchCell} />
                                    }
                                    <Column field="customColumnName" title={intl.formatMessage({ id: "InterfaceConfiguration.ColumnName" })}
                                        editable={params.interfaceConfigParamsType !== window.enumInterfaceConfigParamsType.ImportOfCountQuantities && params.areHeadersEditable} maxLength={1} editor="text" />
                                    <Column field="reportColumnName" title={intl.formatMessage({ id: "InterfaceConfiguration.ReportName" })}
                                        editable={params.interfaceConfigParamsType !== window.enumInterfaceConfigParamsType.ImportOfCountQuantities && params.areHeadersEditable} maxLength={1} editor="text" />



                                    <Column field="position"
                                        title={intl.formatMessage({ id: (configContext.configGlobal.modifiedParamsState && configContext.configGlobal.modifiedParamsState.columnSeparator === window.enumColumnSeparator.None) ? "InterfaceConfiguration.Position" : "InterfaceConfiguration.ColumnNumber" })}
                                        editor="numeric"
                                        editable={(configContext.configGlobal.modifiedParamsState && configContext.configGlobal.modifiedParamsState.columnSeparator === window.enumColumnSeparator.None) ? !configContext.configGlobal.hasHeader : true}
                                        width="200px" />
                                    {configContext.configGlobal.modifiedParamsState && configContext.configGlobal.modifiedParamsState.columnSeparator === window.enumColumnSeparator.None &&
                                        <Column field="lenght" title={intl.formatMessage({ id: "InterfaceConfiguration.Length" })} editor="numeric" editable={!configContext.configGlobal.hasHeader} />
                                    }
                                    {configContext.configGlobal.modifiedParamsState && configContext.configGlobal.modifiedParamsState.columnSeparator === window.enumColumnSeparator.None &&
                                        <Column field="decimalPlaces" title={intl.formatMessage({ id: "InterfaceConfiguration.Decimal" })} editor="numeric" editable={!configContext.configGlobal.hasHeader} />
                                    }
                                    {params.areHeadersEditable && params.interfaceConfigParamsType !== window.enumInterfaceConfigParamsType.ImportOfCountQuantities &&
                                        <Column cell={EditItem} width="180px" filterable={false} />
                                    }
                                    {params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfCountItems &&
                                        <Column field="sortPositionOnCountList" title={intl.formatMessage({ id: "InterfaceConfiguration.SortOrder" })} editor="numeric" />
                                    }
                                    {params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfCountItems &&
                                        <Column field="noOfCharactersUsedForPageBreakOnCountList" title={intl.formatMessage({ id: "InterfaceConfiguration.ChangePageAfterXCharacters" })} cell={PageBreakCell} />
                                    }
                                    {params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfAccountingData &&
                                        <Column field="sortPositionOnDifferenceList" title={intl.formatMessage({ id: "InterfaceConfiguration.SortOrder" })} editor="numeric" />
                                    }
                                    {params.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ExportOfAccountingData &&
                                        <Column field="noOfCharactersUsedForPageBreakOnDifferenceList" title={intl.formatMessage({ id: "InterfaceConfiguration.ChangePageAfterXCharacters" })} cell={PageBreakCell} />
                                    }
                                </Grid>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </DragAndDrop>
        </div>
    )
};

InterfaceConfigurationFieldMappings.propTypes = {
    params: PropTypes.object,
};

export default InterfaceConfigurationFieldMappings;
