import axios from 'axios';
import { postWithPopulationIdHeader, getWithPopulationIdHeader, fetchWithPopulationIdHeader } from './serviceBase';

const baseUrl = '/api/v1.0/Count';

export function exportCountItemsToFile(populationId, successCallback, errorCallback) {
    const url = `${baseUrl}/ExportCountItemsToFile`;
    postWithPopulationIdHeader(url, populationId, null, successCallback, errorCallback);
}

export function getCountingList(page, nextPage, successCallback, errorCallback, populationId) {
    const params = new URLSearchParams();
    if (page || page === 0) {
        params.append('page', page);
    }
    if (nextPage) {
        params.append('nextPage', nextPage);
    }
    
    const url = `${baseUrl}/GetCountingList?` + params;
    getWithPopulationIdHeader(url, populationId, successCallback, errorCallback);
}

export async function importUploadedFileData(fileName, originalFileName, populationId) {
    let config = {
        params: {
            uploadedFileName: fileName,
            originalFileName: originalFileName
        },
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (populationId && populationId.length > 0) {
        config.headers = { ...config.headers, 'X-Remira-PopulationId': populationId };
    }
    return axios.post(`${baseUrl}/ImportUploadedData`, null, config);
}

export async function generatePostAnalysisReports(populationId, successCallback, errorCallback) {
    const url = `${baseUrl}/GeneratePostAnalysisReports`;
    postWithPopulationIdHeader(url, populationId, null, successCallback, errorCallback);
}

export function saveCountingList(request, successCallback, errorCallback, populationId, cancelToken) {
    const url = `${baseUrl}/SaveCountingList`;
    postWithPopulationIdHeader(url, populationId, request, successCallback, errorCallback, cancelToken);
}

export function saveCountingListItems(request, successCallback, errorCallback, populationId, cancelToken) {
    const url = `${baseUrl}/SaveCountingListItems`;
    postWithPopulationIdHeader(url, populationId, request, successCallback, errorCallback, cancelToken);
}

export async function createStaseqCountList(populationId, inputRequest, successCallback, errorCallback) {
    const url = `${baseUrl}/CreateStaseqCountList`;
    return postWithPopulationIdHeader(url, populationId, inputRequest, successCallback, errorCallback);
}

export async function createStasamCountList(populationId, inputRequest, successCallback, errorCallback) {
    const url = `${baseUrl}/CreateStasamCountList`;
    return postWithPopulationIdHeader(url, populationId, inputRequest, successCallback, errorCallback);
}

export async function cancelCountingInProgress(populationId, successCallback, errorCallback) {
    return postWithPopulationIdHeader(`${baseUrl}/CancelRunningCounting`, populationId, null, successCallback, errorCallback);
}

export async function cancelPostAnalysisReportGenerationInProgress(populationId, successCallback, errorCallback) {
    return postWithPopulationIdHeader(`${baseUrl}/CancelPostAnalysisReportGeneration`, populationId, null, successCallback, errorCallback);
}

export async function getCountingDefaults(populationId, successCallback, errorCallback) {
    return getWithPopulationIdHeader(`${baseUrl}/GetCountingDefaults`, populationId, successCallback, errorCallback);
}

export async function getStaseqCountingDefaults(populationId, successCallback, errorCallback) {
    return getWithPopulationIdHeader(`${baseUrl}/GetStaseqCountingDefaults`, populationId, successCallback, errorCallback);
}

export function getDefaultCountListFileName(populationId, successCallback, errorCallback) {
    let url = `${baseUrl}/GetDefaultCountListFileName`;
    getWithPopulationIdHeader(url, populationId, successCallback, errorCallback);
}

export async function fetchDefaultCountListFileName(populationId, errorCallback, cancelToken) {
    let url = `${baseUrl}/GetDefaultCountListFileName`;
    return fetchWithPopulationIdHeader(url, populationId, errorCallback, cancelToken);
}
