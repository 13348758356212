import React, { useEffect, useCallback } from 'react';
import InterfaceConfigurationFieldMappings from "./InterfaceConfigurationFieldMappings";
import InterfaceConfigurationParameters from './InterfaceConfigurationParameters';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useLoading } from '../../Shared/LoadingContext';
import { setInterfaceConfigurationDetails } from '../../../services/interfaceConfigurationsService';
import { ConfigContext } from './ConfigContext';
import { deepClone } from '../../../utils/jsonUtils';
import PropTypes from 'prop-types';

const ConfigInventoryImport = ({data}) => {
    const { setLoading } = useLoading();
    const [isParamChanged, setIsParamChanged] = React.useState(true);
    const [isParamValid, setIsParamValid] = React.useState(true);
    const [isMappingsChanged, setIsMappingsChanged] = React.useState(true);
    const [isMappingValid, setIsMappingValid] = React.useState(true);

    const [interfaceConfigurationParameters, setInterfaceConfigurationParameters] = React.useState({});
    const [interfaceConfigurationFieldMappings, setInterfaceConfigurationFieldMappings] = React.useState({});

    const [doReset, setDoReset] = React.useState(0);
    const configContext = React.useContext(ConfigContext);

    useEffect(() => {
        data.setCurrentTabHasChanges(isParamChanged || isMappingsChanged);
    }, [isParamChanged, isMappingsChanged]);

    const handleSave = useCallback(() => {
        doHandleSave();
    }, [interfaceConfigurationFieldMappings, interfaceConfigurationParameters, data.loadData, data.handleError]);

    const doHandleSave = () => {
        let mappings = deepClone(interfaceConfigurationFieldMappings);
        
        let icp = {
            ...interfaceConfigurationParameters,
            interfaceConfigurationId: configContext.configGlobal.interfaceConfiguration.interfaceConfigurationId,
            interfaceConfigurationFieldMappings: mappings
        }
        setLoading(true);
        setInterfaceConfigurationDetails(
            icp,            
            _ => {
                setLoading(false);
                data.loadData();
            },
            data.handleError);
    }

    const handleReset = useCallback(() => {
        setDoReset(doReset + 1);
    }, [doReset]);

    const goToConfigList = useCallback((_dataItem) => {
        if (data?.doBack) {
            data.doBack();
        }
    }, [data]);

    const isImportConfig = useCallback(() => {
        return data.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfInventoryData ||
            data.interfaceConfigParamsType === window.enumInterfaceConfigParamsType.ImportOfCountQuantities;
    }, [data.interfaceConfigParamsType]);

    const alignAndSetInterfaceConfigurationFieldMappings = useCallback((mappings) => {
        let alignedMappings = mappings;

        if (!isImportConfig() && configContext.configGlobal?.modifiedParamsState?.exportPositionAndPageNumbers) {
            alignedMappings = mappings.map(mapping => ({ ...mapping, position: mapping.position - 2 }));
        }

        setInterfaceConfigurationFieldMappings(alignedMappings);

    }, [configContext.configGlobal?.modifiedParamsState?.exportPositionAndPageNumbers, isImportConfig])

    return <div>
        <InterfaceConfigurationParameters params={{
            ...data,
            setIsParamChanged: setIsParamChanged,
            setIsParamValid: setIsParamValid,
            setInterfaceConfigurationParameters: setInterfaceConfigurationParameters,
            doReset: doReset
        }} />
        <InterfaceConfigurationFieldMappings params={{
            ...data,
            setIsMappingsChanged: setIsMappingsChanged,
            setIsMappingValid: setIsMappingValid,
            setInterfaceConfigurationFieldMappings: alignAndSetInterfaceConfigurationFieldMappings,
            doReset: doReset
        }} />
        <span className="k-form-separator"></span>
        <div className="float-right">
            <Button
                color="secondary"
                onClick={goToConfigList}
            >
                <FormattedMessage id='InterfaceConfiguration.BackToOverview' />
            </Button>
            <Button
                disabled={!(isParamChanged || isMappingsChanged)}
                color="secondary"
                onClick={handleReset}
            >
                <FormattedMessage id='Control.Reset' />
            </Button>
            <Button
                disabled={!((isParamValid && isMappingValid) && (isParamChanged || isMappingsChanged))}
                color="primary"
                onClick={handleSave}
            >
                <FormattedMessage id='Control.Save' />
            </Button>
        </div>
    </div>
}

ConfigInventoryImport.propTypes = {
    data: PropTypes.object
};

export default ConfigInventoryImport

