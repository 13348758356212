import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import "hammerjs";
import axios from 'axios';
import SamplingTabs from './SamplingTabs';
import { FormattedMessage } from 'react-intl';
import LongRunningBackgroundTaskIndicatorDialog from '../../../Shared/LongRunningBackgroundTaskIndicatorDialog';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useLoading } from "../../../Shared/LoadingContext";
import { wizardEvents, wizardStep } from '../Wizard';
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import { UserContext } from '../../../UserContext';
import {
    cancelImportInProgress,
    getSamplingStatistics
} from '../../../../services/inventoryService';
import { trigger, on, off } from '../../../../actions/events';

export const importStatusMessage = (importStatus) => {
    let text = '';
    switch (importStatus) {
        case window.enumBackgroundTaskStatus.DataImportInProgress:
            text = (<FormattedMessage id='ImportResult.ImportStatus.DataImportInProgress' />);
            break;
        case window.enumBackgroundTaskStatus.DataImportSucceeded:
            text = (<FormattedMessage id='ImportResult.ImportStatus.DataImportSucceeded' />);
            break;
        case window.enumBackgroundTaskStatus.DataImportFailed:
            text = (<FormattedMessage id='ImportResult.ImportStatus.DataImportFailed' />);
            break;
        case window.enumBackgroundTaskStatus.DataOrColumnMappingError:
            text = (<FormattedMessage id='ImportResult.ImportStatus.DataOrColumnMappingError' />);
            break;
        case window.enumBackgroundTaskStatus.ReportGenerationInProgress:
            text = (<FormattedMessage id='ImportResult.ImportStatus.ReportGenerationInProgress' />);
            break;
        case window.enumBackgroundTaskStatus.ImportCancelled:
            text = (<FormattedMessage id='ImportResult.ImportStatus.DataImportCancelled' />);
            break;
        case window.enumBackgroundTaskStatus.ReportGenerationSkipped:
            text = (<FormattedMessage id='ImportResult.ImportStatus.DataImportSucceeded' />);
            break;
        case window.enumBackgroundTaskStatus.ReportGenerationFailed:
            text = (<FormattedMessage id='ImportResult.ImportStatus.ReportGenerationFailed' />);
            break;
        case window.enumBackgroundTaskStatus.StratificationInProgress:
            text = (<FormattedMessage id='ImportResult.ImportStatus.StratificationInProgress' />);
            break;
        case window.enumBackgroundTaskStatus.StructureAnalysisInProgress:
            text = (<FormattedMessage id='ImportResult.ImportStatus.StructureAnalysisInProgress' />);
            break;
        case window.enumBackgroundTaskStatus.LorenzCurveInProgress:
            text = (<FormattedMessage id='ImportResult.ImportStatus.LorenzCurveInProgress' />);
            break;
        case window.enumBackgroundTaskStatus.CreateCountListInProgress:
            text = (<FormattedMessage id='ImportResult.ImportStatus.CreateCountListInProgress' />);
            break;
        case window.enumBackgroundTaskStatus.SignalRConnectionFailed:
            text = (<FormattedMessage id='ImportResult.ImportStatus.SignalRConnectionFailed' />);
            break;
        case window.enumBackgroundTaskStatus.WaitingToStart:
            text = (<FormattedMessage id='ImportResult.ImportStatus.WaitingToStart' />);
            break;
        default:
    }
    return text;
}

const ImportResult = ({ setContinueBtnDisabled, isPerpetualInventory, isHighQuality }) => {
    const pageId = 'pageImportResult';
    const cancelTokenSource = axios.CancelToken.source();
    const dispatchExceptionDialog = useExceptionDialog();
    const dispatchNotification = useNotification();
    const { setLoading } = useLoading();

    const [enableReports, setEnableReports] = useState(false);
    const [dataOrColumnMappingError, setDataOrColumnMappingError] = useState(false);
    const [statistics, setStatistics] = useState(null);

    const userContext = React.useContext(UserContext);
    const wizzardContext = React.useContext(WizzardContext);
    const selectedPopulation = useSelector(state => state.dashboard.population);

    const importInProgress = wizzardContext.wizzardGlobal.Import.IsBackgroundTaskRunning;
    const importFinishedWithError = wizzardContext.wizzardGlobal.Import.IsBackgroundTaskFinishedWithError;
    const waitingForErp = wizzardContext.wizzardGlobal.Import.WaitingForErp;
    const isCompleteCountInventory = wizzardContext.wizzardGlobal.SupportData?.inventoryType === window.enumInventoryType.CompleteCount;

    const statusMessageFromProgramLockState = () => {
        let text = '';
        switch (wizzardContext.wizzardGlobal.Import.ProgramLockState) {
            case window.enumProgramLockState.InitialDataLoadingStarted:
                text = (<FormattedMessage id='ImportResult.ImportStatus.DataImportInProgress' />);
                break;
            case window.enumProgramLockState.InitialDataLoaded:
                text = (<FormattedMessage id='ImportResult.ImportStatus.DataImportSucceeded' />);
                break;
            case window.enumProgramLockState.StratumsCreated:
                text = (<FormattedMessage id='ImportResult.ImportStatus.ReportGenerationInProgress' />);
                break;
            case window.enumProgramLockState.CountListCreationStarted:
                text = (<FormattedMessage id='ImportResult.ImportStatus.CreateCountListInProgress' />);
                break;
            default:
                if (!waitingForErp) {
                    text = wizzardContext.wizzardGlobal.Import.ProgramLockState < window.enumProgramLockState.InitialDataLoaded ? <FormattedMessage id='ImportResult.ImportStatus.DataImportInProgress' /> : '';
                }
        }
        return text;
    }

    const getFinalStates = () => {
        let states = [
            window.enumBackgroundTaskStatus.DataImportFailed,
            window.enumBackgroundTaskStatus.ReportGenerationSucceeded,
            window.enumBackgroundTaskStatus.ReportGenerationSkipped,
            window.enumBackgroundTaskStatus.ReportGenerationFailed,
            window.enumBackgroundTaskStatus.ImportCancelled,
            window.enumBackgroundTaskStatus.DataOrColumnMappingError
        ];

        if (!waitingForErp) {
            states.push(window.enumBackgroundTaskStatus.NoBackgroundTaskRunning);
        }

        return states;
    }

    const cancelImport = (populationId) => {
        cancelImportInProgress(populationId,
            () => { console.log('Inventory data import cancellation initiated') },
            error => { dispatchExceptionDialog({ pageId: pageId, message: error }); }
        );
    }

    const cancelButtonClicked = (populationId) => {
        cancelImport(populationId);
    }

    const setValidStateOfCurrentStep = useCallback(valid => {
        trigger(wizardEvents.validStateOfCurrentStepChanged, { step: wizardStep.Sampling, isValid: valid });
    }, []);

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const importTaskFinished = inventoryImportStatus => {
        let successStates = [
            window.enumBackgroundTaskStatus.ReportGenerationSucceeded,
            window.enumBackgroundTaskStatus.ReportGenerationSkipped
        ];
        let success = successStates.includes(inventoryImportStatus);
        removeNotification();

        if (success) {
            loadSamplingStatistics();
        }
        else {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: importStatusMessage(inventoryImportStatus)
            });
        }
        setEnableReports(success && !isCompleteCountInventory);
        setValidStateOfCurrentStep(success);

        if (inventoryImportStatus === window.enumBackgroundTaskStatus.ImportCancelled) {
            trigger(wizardEvents.inventoryImportCancelled, {});
        }

        wizzardContext.dispatchWizzardGlobal({
            type: WIZZARD_ACTIONS.SetProgramLockState,
            payload: {
                ...wizzardContext.wizzardGlobal.Import,
                IsBackgroundTaskRunning: false,
                IsBackgroundTaskFinishedWithError: !success,
                WaitingForErp: false
            }
        });
    }

    const showErrorNotificationAndBlockContinue = useCallback(notificationMsg => {
        dispatchNotification({
            type: NotificationTypes.error,
            pageId: pageId,
            message: notificationMsg
        });

        setDataOrColumnMappingError(true);
        setContinueBtnDisabled(false);
        setValidStateOfCurrentStep(false);
    }, [dispatchNotification, setContinueBtnDisabled, setValidStateOfCurrentStep]);

    const checkRejectedRowCount = useCallback(stats => {
        if (stats?.inventoryData?.rowsRejectedCnt > 0) {
            showErrorNotificationAndBlockContinue(importStatusMessage(window.enumBackgroundTaskStatus.DataOrColumnMappingError));
            return false;
        }
        return true;
    }, [showErrorNotificationAndBlockContinue]);

    const checkPricesImportedForStasam = useCallback(stats => {
        if (stats?.inventoryParameter?.inventoryMethod === window.enumInventoryMethod.Stasam && !stats.pricesImported) {
            showErrorNotificationAndBlockContinue(<FormattedMessage id='ImportResult.Error.NoPricesImported' />);
            return false;
        }
        return true;
    }, [showErrorNotificationAndBlockContinue]);

    const checkStatisticsConsistencyAndDisplayErrorNotification = useCallback(stats => {
        return checkRejectedRowCount(stats) && checkPricesImportedForStasam(stats);
    }, [checkPricesImportedForStasam, checkRejectedRowCount]);

    const checkImportWasFinalized = useCallback(stats => {
        return !stats.pricesImported
            || isCompleteCountInventory
            || stats?.inventoryParameter?.inventoryMethod === window.enumInventoryMethod.Staseq
            || (stats?.inventoryParameter?.inventoryMethod === window.enumInventoryMethod.Stasam && stats.statisticsOfStratification?.stratumStatistics?.length > 0);
    }, [isCompleteCountInventory]);

    const interruptImport = useCallback(() => {
        setEnableReports(false);
        setValidStateOfCurrentStep(false);
    }, [setValidStateOfCurrentStep]);

    const checkStatistics = useCallback(stats => {
        if (checkStatisticsConsistencyAndDisplayErrorNotification(stats)) {
            if (!checkImportWasFinalized(stats)) {
                interruptImport();
            }
            else {
                setEnableReports(!isCompleteCountInventory);
                setValidStateOfCurrentStep(true);
            }
        }
    }, [checkStatisticsConsistencyAndDisplayErrorNotification, checkImportWasFinalized, setValidStateOfCurrentStep, interruptImport, isCompleteCountInventory]);

    const loadSamplingStatistics = useCallback(() => {
        setLoading(true);
        getSamplingStatistics(selectedPopulation?.populationId,
            data => {
                checkStatistics(data);
                setStatistics(data);
                setLoading(false);
            },
            error => {
                setLoading(false);
                console.log('error getting sampling statistics for current population', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
            },
            cancelTokenSource.token
        );
    }, [cancelTokenSource.token, checkStatistics, dispatchExceptionDialog, selectedPopulation?.populationId, setLoading]);

    useEffect(() => {
        if (importFinishedWithError) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: importStatusMessage(window.enumBackgroundTaskStatus.DataImportFailed)
            });
            return;
        }

        if (importInProgress === false && waitingForErp === false) {
            loadSamplingStatistics();
        }

        return () => { cancelTokenSource.cancel(); };
    }, []);

    useEffect(() => {
        on(wizardEvents.reloadImportStatistics, loadSamplingStatistics);

        return () => {
            off(wizardEvents.reloadImportStatistics, loadSamplingStatistics);
        }
    }, [loadSamplingStatistics]);

    return (
        <Row className="wizard-content">
            <Col xs="12" >
                {
                    !waitingForErp &&
                    <SamplingTabs population={selectedPopulation} enableReportPreview={enableReports} enablePdfSummary={enableReports || (isCompleteCountInventory && !importFinishedWithError)}
                        dataOrColumnMappingError={dataOrColumnMappingError} statistics={statistics}
                        importInProgress={importInProgress} importFinishedWithError={importFinishedWithError}
                        readOnly={userContext.userGlobal.userInfo.isController || isCompleteCountInventory}
                        isPerpetualInventory={isPerpetualInventory} isHighQuality={isHighQuality} setContinueBtnDisabled={setContinueBtnDisabled}
                    />
                }
            </Col>
            {(importInProgress || waitingForErp) &&
                <LongRunningBackgroundTaskIndicatorDialog visible={importInProgress || waitingForErp}
                    title={<FormattedMessage id='ImportResult.ImportInProgress' />}
                    waitMessage={<FormattedMessage id='ImportResult.ImportStatus.Wait' />}
                    waitingForErp={waitingForErp}
                    openingStatusText={statusMessageFromProgramLockState()}
                    statusChangeTextProvider={importStatusMessage}
                    finalStates={getFinalStates()}
                    onTaskFinishedCallback={importTaskFinished}
                    buttons={[
                        {
                            text: <FormattedMessage id='ImportResult.AbortImport' />,
                            onClick: cancelButtonClicked,
                            onClickText: <FormattedMessage id='ImportResult.WaitForCancel' />
                        }
                    ]}
                    links={[
                        {
                            text: <FormattedMessage id='Sidebar.Dashboard' />,
                            to: '/'
                        }
                    ]}
                    hideCloseBtn={true}
                />
            }
        </Row>
    )
};

ImportResult.propTypes = {
    setContinueBtnDisabled: PropTypes.func.isRequired,
    isPerpetualInventory: PropTypes.bool.isRequired,
    isHighQuality: PropTypes.bool
};

export default ImportResult;