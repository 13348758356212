import * as React from "react";
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import CountingOnMobileDevice from "./CountingOnMobileDevice";
import EnterCountingTable from "./EnterCountingTable";
import { trigger } from '../../../../actions/events';
import { wizardEvents } from '../Wizard';

import { getPostAnalysisReportStatus, getPostAnalysisSeqReportStatus } from '../../../../services/inventoryService';
import { useLoading } from "../../../Shared/LoadingContext";
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';

const Counting = (props) => {
    const cancelTokenSource = axios.CancelToken.source();
    const { setLoading } = useLoading();
    const intl = useIntl();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const wizzardContext = React.useContext(WizzardContext);
    const pageId = 'Counting';
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const inventoryMethod = wizzardContext.wizzardGlobal.Import.InventoryMethod;
    const isHighQuality = inventoryMethod === window.enumInventoryMethod.Staseq;

    const checkReportsAllowedAndContinue = (continueToReports = false) => {
        setLoading(true);
        let getReportStatusMethod = isHighQuality ? getPostAnalysisSeqReportStatus : getPostAnalysisReportStatus;
        getReportStatusMethod(selectedPopulation?.populationId,
            data => {
                setLoading(false);
                let message = data.reportCanBeCreated ? undefined : data.statusMessage ?? intl.formatMessage({ id: 'Counting.ReportCannotBeCreated' });
                wizzardContext.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetReportsCannotBeCreatedMessage,
                    payload: { ReportsCannotBeCreatedMessage: message }
                });

                if (continueToReports) {
                    if (data.reportCanBeCreated) {
                        trigger(wizardEvents.stepFinalized, {});
                    }
                    else {
                        dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: pageId,
                            message: message
                        });
                    }
                }
            },
            error => {
                setLoading(false);
                console.error('Error during checkReportsAllowedAndContinue - getReportStatusMethod:', getReportStatusMethod.name);
                if (props.handleError) {
                    props.handleError(error, false, pageId);
                }
                else {
                    dispatchExceptionDialog({ pageId: pageId, message: error });
                }
            },
            cancelTokenSource.token
        );
    }

    const isOnMobileDevice = wizzardContext.wizzardGlobal?.Counting?.CountingOption === window.enumCountingOption.OnMobileDevice ||
        (wizzardContext.wizzardGlobal?.Counting?.CountingOption === undefined && wizzardContext.wizzardGlobal?.Import?.CountingOption === window.enumCountingOption.OnMobileDevice);
    const component = isOnMobileDevice
        ? <CountingOnMobileDevice onCountingFinished={checkReportsAllowedAndContinue} handleError={props.handleError} />
        : <EnterCountingTable onCountingFinished={checkReportsAllowedAndContinue} handleError={props.handleError} />;

    React.useEffect(() => {
        if (wizzardContext.wizzardGlobal?.Counting?.CountingOption === undefined && wizzardContext.wizzardGlobal?.Import?.CountingOption !== undefined) {
            wizzardContext.dispatchWizzardGlobal({
                type: WIZZARD_ACTIONS.SetCountNextButton,
                payload: { CountingOption: wizzardContext.wizzardGlobal?.Import?.CountingOption }
            });
        }
        trigger(wizardEvents.refreshButtonStateChanged, isOnMobileDevice);
    }, [isOnMobileDevice]);

    return (
        <div>
            {component}
        </div>
    );
}

export default Counting