import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { getCountingDefaults } from '../../../../services/countService';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { WizzardContext, WIZZARD_ACTIONS } from '../WizzardContext';

const CountListParametersCardStasam = ({ isPerpetualInventory, onCountingParametersValidityChanged, invisible }) => {
    const pageId = 'pageCountListParametersStasam';
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();

    const [countingDefaults, setCountingDefaults] = useState(null);

    const [redrawItemsLeftToCount, setRedrawItemsLeftToCount] = useState(0);
    const [randomSamplingItemsLeftToCount, setRandomSamplingItemsLeftToCount] = useState(0);
    const [completeCountItemsLeftToCount, setCompleteCountItemsLeftToCount] = useState(0);

    const wizzardContext = React.useContext(WizzardContext);
    
    const selectedPopulation = useSelector(state => state.dashboard.population);

    const handleError = useCallback((errorMessage, showNotif = true) => {
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    },[]);

    React.useEffect(() => {
        getCountingDefaults(
            selectedPopulation.populationId,
            result => {
                setCountingDefaults(result.data);
                setRedrawItemsLeftToCount(result.data.redraw.itemsLeftToCount);
                setRandomSamplingItemsLeftToCount(result.data.randomSampling.itemsLeftToCount);
                setCompleteCountItemsLeftToCount(result.data.completeCount.itemsLeftToCount);
            },
            handleError
        );
    }, [selectedPopulation.populationId]);

    const completeCountItemsLeftToCountValid = countingDefaults && (completeCountItemsLeftToCount ?? -1) >= 0 && completeCountItemsLeftToCount <= countingDefaults.completeCount?.itemsLeftToCount;
    const randomSamplingItemsLeftToCountValid = countingDefaults && (randomSamplingItemsLeftToCount ?? -1) >= 0 && randomSamplingItemsLeftToCount <= countingDefaults.randomSampling?.itemsLeftToCount;
    const redrawItemsLeftToCountValid = countingDefaults && (redrawItemsLeftToCount ?? -1) >= 0 && redrawItemsLeftToCount <= countingDefaults.redraw.itemsLeftToCount;

    const isCountingDefaultsValid = () => {
        return completeCountItemsLeftToCountValid
            && randomSamplingItemsLeftToCountValid
            && redrawItemsLeftToCountValid
    }

    useEffect(() => {
        if (isCountingDefaultsValid()) {
            wizzardContext.dispatchWizzardGlobal({
                type: WIZZARD_ACTIONS.SetStasamCounting, payload: {
                    completeCountItemsToCount: completeCountItemsLeftToCount,
                    randomSamplingItemsToCount: randomSamplingItemsLeftToCount,
                    recountItemsToCount: redrawItemsLeftToCount
                }
            });
            onCountingParametersValidityChanged?.(true);
        }
        else {
            onCountingParametersValidityChanged?.(false);
        }
    }, [completeCountItemsLeftToCount, randomSamplingItemsLeftToCount, redrawItemsLeftToCount]);

    const handleRedrawItemsLeftToCountChange = (event) => {
        setRedrawItemsLeftToCount(event.target.value);
    };

    const handleRandomSamplingItemsLeftToCountChange = (event) => {
        setRandomSamplingItemsLeftToCount(event.target.value);
    };

    const handleCompleteCountItemsLeftToCountChange = (event) => {
        setCompleteCountItemsLeftToCount(event.target.value);
    };

    return ( countingDefaults &&
        ( !invisible
        ? <Card >
            <CardBody>
                <div>
                    <Row>
                        <Col xs="3">
                        </Col>
                        <Col xs="3">
                            <FormattedMessage id='CountListParameters.Maximal' />
                        </Col>
                        <Col xs="3">
                            <FormattedMessage id='CountListParameters.Counted' />
                        </Col>
                        <Col xs="3">
                            <FormattedMessage id='CountListParameters.Now' />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs="3">
                            <FormattedMessage id='CountListParameters.CompleteCount' />
                        </Col>
                        <Col xs="3">
                            <Input
                                disabled={true}
                                id="countingDefaults.completeCount.totalItemsToCount"
                                value={countingDefaults.completeCount?.totalItemsToCount}
                            />
                        </Col>
                        <Col xs="3">
                            <Input
                                disabled={true}
                                id="countingDefaults.completeCount.alreadyCountedItems"
                                value={countingDefaults.completeCount?.alreadyCountedItems}
                            />
                        </Col>
                        <Col xs="3">
                            <NumericTextBox
                                id="countingDefaults.completeCount.itemsLeftToCount"
                                value={completeCountItemsLeftToCount}
                                disabled={!isPerpetualInventory || countingDefaults.completeCount?.itemsLeftToCount === 0}
                                min={0}
                                max={countingDefaults.completeCount?.itemsLeftToCount}
                                onChange={handleCompleteCountItemsLeftToCountChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs="3">
                            <FormattedMessage id='CountListParameters.RandomSampling' />
                        </Col>
                        <Col xs="3">
                            <Input
                                disabled={true}
                                id="countingDefaults.randomSampling.totalItemsToCount"
                                value={countingDefaults.randomSampling.totalItemsToCount}
                            />
                        </Col>
                        <Col xs="3">
                            <Input
                                disabled={true}
                                id="countingDefaults.randomSampling.alreadyCountedItems"
                                value={countingDefaults.randomSampling.alreadyCountedItems}
                            />
                        </Col>
                        <Col xs="3">
                            <NumericTextBox
                                id="countingDefaults.randomSampling.itemsLeftToCount"
                                value={randomSamplingItemsLeftToCount}
                                disabled={!isPerpetualInventory || countingDefaults.randomSampling.itemsLeftToCount === 0}
                                min={0}
                                max={countingDefaults.randomSampling.itemsLeftToCount}
                                onChange={handleRandomSamplingItemsLeftToCountChange}
                            />
                        </Col>
                    </Row>
                    {isPerpetualInventory &&
                        <Row className="mt-2">
                            <Col xs="3">
                                <FormattedMessage id='CountListParameters.Redraw' />
                            </Col>
                            <Col xs="3">
                                <Input
                                    disabled={true}
                                    id="countingDefaults.redraw.totalItemsToCount"
                                    value={countingDefaults.redraw.totalItemsToCount}
                                />
                            </Col>
                            <Col xs="3">
                                <Input
                                    disabled={true}
                                    id="countingDefaults.redraw.alreadyCountedItems"
                                    value={countingDefaults.redraw.alreadyCountedItems}
                                />
                            </Col>
                            <Col xs="3">
                                <NumericTextBox
                                    disabled={!isPerpetualInventory || countingDefaults.redraw.itemsLeftToCount === 0}
                                    id="countingDefaults.redraw.itemsLeftToCount"
                                    value={redrawItemsLeftToCount}
                                    min={0}
                                    max={countingDefaults.redraw.itemsLeftToCount}
                                    onChange={handleRedrawItemsLeftToCountChange}
                                />
                            </Col>
                        </Row>
                    }
                </div>
            </CardBody>
        </Card>
        :<></>)
    );
};

CountListParametersCardStasam.propTypes = {
    isPerpetualInventory: PropTypes.bool,
    onCountingParametersValidityChanged: PropTypes.func,
    invisible: PropTypes.bool
};

export default CountListParametersCardStasam;