import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { useLoading } from '../Shared/LoadingContext';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { getAdjustElasticPoolsTaskTime, adjustElasticPoolsAt, cancelScheduledAdjustmentOfElasticPools } from '../../services/supportService';

const AdjustElasticPools = () => {
    const pageId = 'AdjustElasticPools';
    const intl = useIntl();
    const dispatchNotification = useNotification();
    const { setLoading } = useLoading();
    const dispatchExceptionDialog = useExceptionDialog();

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [scheduleOnMessage, setScheduleOnMessage] = useState();

    const [selectedDateTime, setSelectedDateTime] = useState(new Date());

    useEffect(() => {
        setLoading(true);
        getAdjustElasticPoolsTaskTime(
            result => {
                setLoading(false);
                console.log('getScheduledTaskTime result:', result);
                if (result) {
                    setSelectedDateTime(new Date(result));
                    setScheduleOnMessage(intl.formatMessage({ id: 'SupportTools.ScheduleOnMessage' }));
                }
            },
            handleError);
    }, []);

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const handleChange = (event: any) => {
        setSelectedDateTime(event.value);
    };

    const adjustElasticPool = () => {
        setLoading(true);
        adjustElasticPoolsAt(
            selectedDateTime,
            () => {
                setLoading(false);
                setScheduleOnMessage(intl.formatMessage({ id: 'SupportTools.ScheduleOnMessage' }));
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='SupportTools.AdjustElasticPoolsSuccess' />
                });
            },
            handleError);
    };

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            adjustElasticPool();
        }
    };

    const handleExecuteAdjustElasticPool = () => {
        setConfirmDialogTitle(intl.formatMessage({ id: 'SupportTools.ConfirmationTitleAdjustElasticPool' }));
        setConfirmDialogDetail(intl.formatMessage({ id: 'SupportTools.ConfirmationTextAdjustElasticPool' },
            { ScheduleDate: selectedDateTime.toLocaleDateString() + ' ' + selectedDateTime.toLocaleTimeString() }));
        setIsConfirmDialogVisible(true);
    }

    const handleCancelSchedule = () => {
        setLoading(true);
        cancelScheduledAdjustmentOfElasticPools(
            () => {
                setLoading(false);
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='SupportTools.AdjustmentOfElasticPoolsCancelled' />
                });
                setScheduleOnMessage(undefined);
            },
            handleError);
    }

    return (

        <div className="content">
            <DateTimePicker
                disabled={scheduleOnMessage}
                value={selectedDateTime}
                onChange={handleChange}
                format="yyyy-MM-dd HH:mm:ss"
            />
            &nbsp;&nbsp;&nbsp;
            <span>{scheduleOnMessage}</span>
            <span className="k-form-separator"></span>
            <div className="float-right">
                <Button
                    color="primary"
                    onClick={handleExecuteAdjustElasticPool}
                    disabled={!!scheduleOnMessage}
                >
                    <FormattedMessage id='SupportTools.AdjustElasticPools' />
                </Button>
            </div>
            {scheduleOnMessage && <div className="float-right">
                <Button
                    color="primary"
                    onClick={handleCancelSchedule}
                >
                    <FormattedMessage id='SupportTools.CancelSchedule' />
                </Button>
            </div>}

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </div>
    )
}

export default AdjustElasticPools