import axios from 'axios';
import { createUserManager, signoutRedirect } from './userService';
import store from '../store';
import { storeUser } from '../actions/authActions';
import { setAuthHeader, getTokenFromAuthHeader } from '../utils/axiosHeaders';

export const SessionKeyIsAutomaticLogout = "isAutomaticLogout";

const exchangeRefreshTokenForAccessToken = async (userManager) => {
    try {
        // The library will use the refresh token grant to get a new access token
        await userManager.signinSilent();
    }
    catch (e) {
        // When the session expires this will fail with an 'invalid_grant' response
        if (e.message === 'invalid_grant') {
            console.log('user session expired...');
            signoutRedirect(userManager);
        } else {
            // do nothing, the caller function will check if the token refresh was sucessful or not
            console.log('access token renewal failed');
            console.log(e);
        }
    }
}

export async function renewAccessToken(userManager) {
    let user = await userManager.getUser();

    if (user?.refresh_token && user.refresh_token.length > 0) {
        await exchangeRefreshTokenForAccessToken(userManager);
    } else {
        console.log('user is not authenticated, redirecting to login page...');
        signoutRedirect(userManager);
    }

    user = await userManager.getUser();

    if (user?.access_token) {
        store.dispatch(storeUser(user));
        console.log('user access token renewed');

        return new Promise(resolve => {
            setTimeout(() => resolve(user), 100);
        });
    }
    else {
        return Promise.reject(user);
    }
}

axios.interceptors.request.use(
    async function (req) {
        let user = null;
        let userManager = null;

        try {
            userManager = createUserManager();
            user = await userManager.getUser();
        }
        catch (e) {
            console.log('user is not authenticated...');
        }

        if (user) {
            if (user.expired) {
                await renewAccessToken(userManager)
                    .then(
                        (success) => { return req },
                        (err) => {
                            console.log('access token renewal failed, redirecting to login page...');
                            console.log(err);

                            sessionStorage.setItem(SessionKeyIsAutomaticLogout, 'true');

                            signoutRedirect(userManager);
                        }
                    );
            }
            else {
                const token = getTokenFromAuthHeader();
                if (token !== user.access_token) {
                    setAuthHeader(user.access_token);
                }
            }
        }

        return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export function get(url, successCallback, errorCallback, cancelToken) {
    axios.get(url, { cancelToken: cancelToken })
        .then(response => {
            successCallback(response.data);
        })
        .catch(error => {
            handleError(error, errorCallback);
        });
}

export function getWithPopulationIdHeader(url, populationId, successCallback, errorCallback, cancelToken) {
    const config = createConfigWithPopulationIdHeader(populationId, cancelToken);
    axios.get(url, config)
        .then(response => {
            successCallback(response.data);
        })
        .catch(error => {
            handleError(error, errorCallback);
        });
}

export async function fetchWithPopulationIdHeader(url, populationId, errorCallback, cancelToken) {
    try {
        const config = createConfigWithPopulationIdHeader(populationId, cancelToken);
        // Call axios.get and wait for the response
        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        errorCallback?.(error);
    }
}
export function getAllWithPopulationIdHeaders(requests, successCallback, errorCallback, cancelToken) {
    let promises = requests.map(request => {
        const config = createConfigWithPopulationIdHeader(request.populationId, cancelToken);
        return axios.get(request.url, config)
            .catch(error => {
                handleError(error, errorCallback);
                return error;
            });
    });

    Promise.all(promises)
        .then(values => {
            const successValues = values.filter(x => x.data != null).map(x => x.data);
            successCallback(successValues);
        });
}

export function getWithParams(url, parameter, successCallback, errorCallback, cancelToken) {
    axios.get(url, {
        params: parameter,
        cancelToken: cancelToken
    })
    .then(response => {
        successCallback(response.data);
    })
    .catch(error => {
        handleError(error, errorCallback);
    });
}

export function post(url, payload, successCallback, errorCallback) {
    axios.post(url, payload)
        .then(response => {
            successCallback(response.data);
        })
        .catch(error => {
            handleError(error, errorCallback);
        });
}

export function postWithParams(url, payload, parameter, successCallback, errorCallback) {
    const config = { params: parameter };
    axios.post(url, payload, config)
        .then(response => {
            successCallback(response.data);
        })
        .catch(error => {
            handleError(error, errorCallback);
        });
}

export function postWithPopulationIdHeader(url, populationId, payload, successCallback, errorCallback, cancelToken) {
    const config = createConfigWithPopulationIdHeader(populationId, cancelToken);

    axios.post(url, payload, config)
        .then(response => {
            successCallback(response.data);
        })
        .catch(error => {
            handleError(error, errorCallback);
        });
}

export function put(url, payload, successCallback, errorCallback) {
    axios.put(url, payload)
        .then(response => {
            successCallback(response.data);
        })
        .catch(error => {
            handleError(error, errorCallback);
        });
}

export function deleteResource(url, successCallback, errorCallback) {
    axios.delete(url)
        .then(response => {
            successCallback(response.data);
        })
        .catch(error => {
            handleError(error, errorCallback);
        });
}

export function getFile(url, payload, successCallback, errorCallback, populationId) {
    let config = createConfigWithPopulationIdHeader(populationId);
    config.responseType = 'blob';
    return axios.get(url, config)
        .then(response => {
            return getFileFromBlobResponse(response);
        })
        .then(data => {
            successCallback(data);
        })
        .catch(error => {
            handleError(error, errorCallback);
        });
}

export function getFileFromBlobResponse(response) {
    let fileName = '';
    let contentType = '';
    if (response?.headers) {
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
            const matches = asciiFilenameRegex.exec(contentDisposition);
            if (matches != null && matches[2]) {
                fileName = matches[2];
            }
        }
        contentType = response.headers['content-type'];
    }
    const blob = response?.data;
    return new File([blob], fileName, { type: contentType });
}

export function getFileFromReportItemListResponse(response) {
    const fileContent = atob(response?.fileContent);
    const fileName = response?.fileName;
    const contentType = response?.contentType;

    const uint8Array = new Uint8Array(fileContent.length);
    for (let i = 0; i < fileContent.length; i++) {
        uint8Array[i] = fileContent.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: contentType });
    return new File([blob], fileName, { type: contentType });
}

function createConfigWithPopulationIdHeader(populationId, cancelToken) {
    let config = { cancelToken: cancelToken };
    if (populationId && populationId.length > 0) {
        config.headers = {
            'X-Remira-PopulationId': populationId,
            'Content-Type': 'application/json'
        }
    }
    return config;
}

function invokeErrorCallback(errorCallback, error) {
    if (errorCallback && error) {
        errorCallback(error, false);
    }
}

async function handleError(error, errorCallback) {
    if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
    } else if (error?.response) {
        if (error.response.status === 400) {
            handle400ErrorCode(error, errorCallback);
        } else if (error.response.data instanceof Blob) {
            const err = await getErrorFromBlob(error.response.data);
            invokeErrorCallback(errorCallback, err.detail);
        }
        else if (error.response.status === 401) {
            sessionStorage.setItem(SessionKeyIsAutomaticLogout, 'true');

            let userManager = createUserManager();
            signoutRedirect(userManager);
        }
        else {
            invokeErrorCallback(errorCallback, error.response.data ?? error.message);
        }
    }
    else {
        invokeErrorCallback(errorCallback, error);
    }
}

function handle400ErrorCode(error, errorCallback) {
    let errors = [];
    if (error.response.data.errors) {
        errors = getErrorsFromBadRequestResponse(error.response.data.errors);
    } else if (typeof error.response.data == "string") {
        errors.push(error.response.data);
    }
    else {
        errors = getErrorsFromBadRequestResponse(error.response.data);
    }
    errorCallback(errors.join('\n'), true);
}

function getErrorsFromBadRequestResponse(validationErrorDictionary) {
    const errors = [];
    for (const fieldName in validationErrorDictionary) {
        if (validationErrorDictionary.hasOwnProperty(fieldName)) {
            for (const error of validationErrorDictionary[fieldName]) {
                errors.push(error);
            }
        }
    }
    return errors;
}

async function getErrorFromBlob(errBlob) {
    let err = {};
    try {
        const errJson = await errBlob.text();
        err = errJson && errJson.length > 0 ? JSON.parse(errJson) : {};
    } catch (error) {
        console.error(error);
    }
    return err;
}
