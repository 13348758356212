import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import WarehouseLocation from './Dashboard/WarehouseLocation';
import CustomerSelection from './Dashboard/CustomerSelection';
import { useLoading } from './Shared/LoadingContext';
import { useNotification } from './Shared/Notifications/NotificationProvider';
import { NotificationTypes } from './Shared/Notifications/Notification';
import { storeCustomer } from '../actions/dashboardActions';
import { Col, Row, Button, Card, CardBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useExceptionDialog } from './Shared/ExceptionDialog/ExceptionDialogProvider';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { GetDisabledAddLocationButtonTooltip, IsAddingPopulationAllowedBySubscription, UserContext } from './UserContext';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCustomerInfo, setGTCAcceptedForCustomer } from '../services/dashboardService';
import { getAllStatistics, getAllCachedStatistics } from '../services/statisticsCollectorService';
import { SessionKeyIsAutomaticLogout } from '../services/serviceBase';
import { getNLSEntry } from '../services/supportService';

function Home() {
    const intl = useIntl();
    const userContext = React.useContext(UserContext);
    let history = useHistory();
    const userProfile = useSelector(state => state.profile.profile);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const dispatch = useDispatch();

    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'Dashboard';
    const cancelTokenSource = axios.CancelToken.source();

    const [maintenanceWarningText, setMaintenanceWarningText] = useState('');
    const [loadingPopulationStatistics, setLoadingPopulationStatistics] = useState(false);
    const [currentCustomerId, setCurrentCustomerId] = useState(null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [customer, setCustomer] = useState(null);

    const isAddingPopulationAllowedBySubscription = IsAddingPopulationAllowedBySubscription();
    const getDisabledAddLocationButtonTooltip = GetDisabledAddLocationButtonTooltip();

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    useEffect(() => {
        return () => {
            removeNotification();
        };
    }, []);

    useEffect(() => {
        const isAutomaticLogout = sessionStorage.getItem(SessionKeyIsAutomaticLogout);
        if (isAutomaticLogout) {
            return;
        }

        if (userContext?.userGlobal?.userInfo && !userContext.userGlobal.userInfo.isGTCAccepted) {
            setGTCAccepted();
        }
    }, [userContext.userGlobal.userInfo]);

    useEffect(() => {
        const isAutomaticLogout = sessionStorage.getItem(SessionKeyIsAutomaticLogout);
        if (isAutomaticLogout) {
            return;
        }

        getMaintenanceWarningText();
    }, [userProfile.userLanguage]);

    const getMaintenanceWarningText = () => {
        getNLSEntry(
            {
                language: userProfile.userLanguage,
                key: window.nlsKeys.NLS_DashboardHint
            },
            (response) => {
                setMaintenanceWarningText(response);
            }, (error) => {
                console.log(error);
            });
    };

    const handleError = useCallback((errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    },[dispatchExceptionDialog, dispatchNotification, setLoading]);

    const setGTCAccepted = () => {
        setLoading(true);
        setGTCAcceptedForCustomer(
            () => {
                setLoading(false);
            },
            handleError);
    };

    const updateStatisticsInWarehouseLocation = (warehouseLocation, values) => {
        warehouseLocation.populations.forEach(population => {
            const value = values.find(x => x.populationId === population.populationId);
            if (value) {
                population.lastStockItemImportDate = value.lastStockItemImportDate;
                population.countedItems = value.countedItems;
                population.drawnItems = value.drawnItems;
                population.inaccessibleStatistics = value.inaccessibleStatistics;
                population.programLockState = value.programLockState;
            }
        });
    };

    const updateCustomerWithStatisticsData = (rawStatistics, fromCache) => {
        const stats = calculateStatistics(rawStatistics);

        const updatedCustomer = {
            ...customerInfo,
            transferredStockValue: stats.totalTransferredStockValue,
            drawnSamples: stats.totalDrawnSamples,
            savedEffort: stats.savedEffort,
            hasInventoryCompleted: stats.hasInventoryCompleted,
            numberOfPositions: stats.totalNoOfLoadedStockItems,
            statisticsUpdated: !fromCache
        };

        updatedCustomer.warehouseLocations.forEach(warehouseLocation => {
            updateStatisticsInWarehouseLocation(warehouseLocation, rawStatistics);
        });
        setCustomer(updatedCustomer);
    };

    const getAllCustomerPopulationIds = useCallback(() => {
        let populationIds = [];
        if (customerInfo?.warehouseLocations?.length > 0) {
            customerInfo.warehouseLocations.forEach(warehouseLocation => {
                warehouseLocation.populations.forEach(population => {
                    populationIds.push(population.populationId);
                });
            });
        }
        return populationIds;
    }, [customerInfo]);

    const updateCustomerStatisticsFromCache = useCallback(() => {
        let populationIds = getAllCustomerPopulationIds();

        if (populationIds.length > 0) {
            setLoading(true);
            getAllCachedStatistics(
                populationIds,
                values => {
                    setLoading(false);
                    updateCustomerWithStatisticsData(values, true);
                },
                handleError,
                cancelTokenSource.source
            );
        }
    }, [handleError, setLoading, updateCustomerWithStatisticsData, getAllCustomerPopulationIds]);

    const getCustomer = useCallback((customerId) => {
        setLoading(true);
        getCustomerInfo(
            customerId,
            customerInfo => {
                setLoading(false);
                setCustomerInfo(customerInfo);
            },
            handleError,
            cancelTokenSource.source
        );
    }, [handleError, setLoading]);

    useEffect(() => {
        const isAutomaticLogout = sessionStorage.getItem(SessionKeyIsAutomaticLogout);
        if (isAutomaticLogout) {
            return;
        }

        if (!currentCustomer?.customerId || currentCustomer.customerId === currentCustomerId) {
            return;
        }
        setCurrentCustomerId(currentCustomer.customerId);
        getCustomer(currentCustomer.customerId);

    }, [currentCustomer]);

    const updateCustomerStatisticsFromPopulations = () => {
        let populationIds = getAllCustomerPopulationIds();
        if (populationIds.length > 0) {
            console.log('query statistics from populations...');
            setLoadingPopulationStatistics(true);
            getAllStatistics(
                populationIds,
                values => {
                    console.log('statistics from populations returned', values);
                    if (values?.length > 0) {
                        setLoadingPopulationStatistics(false);
                        updateCustomerWithStatisticsData(values, false);
                    }
                },
                handleError,
                cancelTokenSource.token
            );
        }
    };

    useEffect(() => {
        if (!customerInfo) {
            return;
        }
        updateCustomerStatisticsFromCache();

    }, [customerInfo]);

    useEffect(() => {
        if (!currentCustomer || !customer) {
            return;
        }

        const updatedCurrentCustomer = {
            ...currentCustomer,
            numberOfPositions: customer.numberOfPositions,
            statisticsUpdated: customer.statisticsUpdated
        };
        dispatch(storeCustomer(updatedCurrentCustomer));
        if (!customer.statisticsUpdated) {
            updateCustomerStatisticsFromPopulations();
        }

        return () => { cancelTokenSource.cancel(); };

    }, [customer]);

    const calculateStatistics = (values) => {
        const totalTransferredStockValue = values.reduce((previousValue, currentValue) => previousValue + currentValue.transferredStockValue, 0);
        const totalNoOfLoadedStockItems = values.reduce((previousValue, currentValue) => previousValue + currentValue.noOfLoadedStockItems, 0);
        const totalDrawnSamples = values.reduce((previousValue, currentValue) => previousValue + currentValue.drawnSamples, 0);

        const savedEffortAbsolute = totalNoOfLoadedStockItems - totalDrawnSamples;
        const savedEffortPercentage =
            totalNoOfLoadedStockItems !== 0
                ? savedEffortAbsolute / totalNoOfLoadedStockItems * 100
                : 0;

        const hasInventoryCompleted = values.some(x => x.isInventoryCompleted);

        return {
            totalTransferredStockValue: totalTransferredStockValue,
            totalNoOfLoadedStockItems: totalNoOfLoadedStockItems,
            totalDrawnSamples: totalDrawnSamples,
            savedEffort: savedEffortPercentage,
            hasInventoryCompleted: hasInventoryCompleted
        };
    };

    const handleCustomerChanged = (cust) => {
        setCustomer(null);
        dispatch(storeCustomer(cust));
    };

    const addLocationClicked = () => {
        history.push({
            pathname: '/administration/warehousemanagement/add'
        });
    }

    return (
        <div className="content">
            <Row>
                <Col xs="12" lg="9">
                    <h1>
                        <FormattedMessage id='Home.WarehouseLocations' />&nbsp;
                        {userContext.userGlobal.userInfo && (
                            <CustomerSelection
                                customer={currentCustomer}
                                customers={userContext.userGlobal.userInfo.accessibleCustomers}
                                isStatcontrolAdmin={userContext.userGlobal.userInfo.isStatcontrolAdmin}
                                onCustomerChanged={handleCustomerChanged}
                            />
                        )}
                        {userContext.userGlobal.userInfo?.isAddingWarehouseLocationAllowed && (
                            <div className="float-right">
                                <ReactTooltip place="top" backgroundColor='#41b6e6' effect="solid" id="warehouseLocationAddTip" className="generic-tooltip" />
                                <span data-tip={getDisabledAddLocationButtonTooltip} data-for="warehouseLocationAddTip">
                                    <Button color="primary" onClick={addLocationClicked}
                                        data-for="warehouseLocationAddTip"
                                        data-tip={intl.formatMessage({ id:'Home.AddLocation' })}
                                        disabled={!isAddingPopulationAllowedBySubscription}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </span>
                            </div>
                        )}
                    </h1>
                    <Row>
                        {customer?.warehouseLocations?.map(warehouseLocation =>
                            <WarehouseLocation
                                key={warehouseLocation.warehouseLocationId}
                                warehouseLocation={warehouseLocation}
                                language={userProfile.userLanguage}
                                isAddingPopulationAllowed={userContext.userGlobal.userInfo.isAddingPopulationAllowed}
                            />
                        )}
                    </Row>
                </Col>

                <Col xs="6" lg="3">
                    {
                        loadingPopulationStatistics &&
                        <Card className="card-chart" >
                            <CardBody >
                                <h5>
                                    {intl.formatMessage({ id: 'Home.LoadingStatistics' })}
                                </h5>
                            </CardBody>
                        </Card>
                    }

                    {
                        maintenanceWarningText &&
                        <Card className="card-chart" >
                            <CardBody >
                                <h4 style={{ color: "orange" }}>
                                    {maintenanceWarningText}
                                </h4>
                            </CardBody>
                        </Card>
                    }

                </Col>
            </Row>
        </div>
    );
}

export default Home
