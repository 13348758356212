import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { getStaseqCountingDefaults } from '../../../../services/countService';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { WizzardContext, WIZZARD_ACTIONS } from '../WizzardContext';

const CountListParametersCardStaseq = ({
    isPerpetualInventory,
    expandOnStart,
    onCountingParametersValidityChanged,
    onSequentialTestItemsToCountChanged,
    onCompleteCountItemsToCountChanged,
    onInitalSampleItemsToCountChanged
}) => {
    const pageId = 'pageCountListParametersCardStaseq';
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const intl = useIntl();

    const [staseqCountingDefault, setStaseqCountingDefault] = useState(null);

    const [sequentialTestItemsToCount, setSequentialTestItemsToCount] = useState(0);
    const [completeCountItemsToCount, setCompleteCountItemsToCount] = useState(0);
    const [initalSampleItemsToCount, setInitalSampleItemsToCount] = useState(0);
    const [expanded, setExpanded] = React.useState(expandOnStart ?? false);

    const wizzardContext = React.useContext(WizzardContext);

    const notifyError = useCallback((errorMessage) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: 'pageInventoryWizard.Sampling',
        message: errorMessage
    }),[dispatchNotification]);

    const selectedPopulation = useSelector(state => state.dashboard.population);

    const handleError = useCallback((errorMessage, showNotif = true) => {
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    },[]);

    React.useEffect(() => {
        if (selectedPopulation.populationId) {
            getStaseqCountingDefaults(
                selectedPopulation.populationId,
                result => {
                    setStaseqCountingDefault(result.data);
                    setCompleteCountItemsToCount(result.data.completeCount.itemsLeftToCount);
                    setInitalSampleItemsToCount(result.data.initalSample.itemsLeftToCount);
                    setSequentialTestItemsToCount(result.data.sequentialTest.itemsLeftToCount);
                },
                handleError
            );
        }
    }, [selectedPopulation.populationId]);

    const totalItemsToCount = completeCountItemsToCount + initalSampleItemsToCount + sequentialTestItemsToCount;
    const staseqAlreadyCountedItems = (staseqCountingDefault?.completeCount?.alreadyCountedItems ?? 0) +
        (staseqCountingDefault?.initalSample?.alreadyCountedItems ?? 0) +
        (staseqCountingDefault?.sequentialTest?.alreadyCountedItems ?? 0);
    const staseqMaxItemsToCount = (staseqCountingDefault?.totalNoOfItems ?? 0) - staseqAlreadyCountedItems - 1;

    const checkHQTotalItemsToCount = useCallback(() => {
        if (totalItemsToCount > staseqMaxItemsToCount) {
            if (staseqCountingDefault) {
                notifyError(intl.formatMessage({ id: 'CountListParameters.SeqMaxNumberOfItemsToCount' }, { MaxItemsToCount: staseqMaxItemsToCount }));
            }
            return false;
        }
        return true;
    }, [intl, notifyError, staseqCountingDefault, staseqMaxItemsToCount, totalItemsToCount]);

    const completeCountItemsToCountValid = staseqCountingDefault && (completeCountItemsToCount ?? -1) >= 0 && completeCountItemsToCount <= staseqMaxItemsToCount;
    const initalSampleItemsToCountValid = staseqCountingDefault && (initalSampleItemsToCount ?? -1) >= 0 && initalSampleItemsToCount <= staseqMaxItemsToCount;
    const sequentialTestItemsToCountValid = staseqCountingDefault && (sequentialTestItemsToCount ?? -1) >= 0 && sequentialTestItemsToCount <= staseqMaxItemsToCount;

    const isCountingDefaultsValid = () => {
        return completeCountItemsToCountValid
            && initalSampleItemsToCountValid
            && sequentialTestItemsToCountValid
            && checkHQTotalItemsToCount()
    }

    useEffect(() => {
        if (isCountingDefaultsValid()) {
            wizzardContext.dispatchWizzardGlobal({
                type: WIZZARD_ACTIONS.SetStaseqCounting, payload: {
                    completeCountItemsToCount: completeCountItemsToCount,
                    initalSampleItemsToCount: initalSampleItemsToCount,
                    sequentialTestItemsToCount: sequentialTestItemsToCount
                }
            });
            onCountingParametersValidityChanged?.(true);
        }
        else {
            onCountingParametersValidityChanged?.(false);
        }
    }, [completeCountItemsToCount, initalSampleItemsToCount, sequentialTestItemsToCount])

    const handleSequentialTestItemsToCountChange = (event) => {
        setSequentialTestItemsToCount(event.target.value);
    };

    const handleCompleteCountItemsToCountChange = (event) => {
        setCompleteCountItemsToCount(event.target.value);        
    };

    const handleInitalSampleInputEnabledChange = (event) => {
        setInitalSampleItemsToCount(event.target.value);
    };

    useEffect(() => {
        onSequentialTestItemsToCountChanged?.(sequentialTestItemsToCount);
    }, [sequentialTestItemsToCount]);

    useEffect(() => {
        onCompleteCountItemsToCountChanged?.(completeCountItemsToCount);
    }, [completeCountItemsToCount])

    useEffect(() => {
        onInitalSampleItemsToCountChanged?.(initalSampleItemsToCount);
    }, [initalSampleItemsToCount]);

    return (staseqCountingDefault ?
        <Card >
            <CardBody>
                <ExpansionPanel
                    title={intl.formatMessage({ id: 'CountListParameters.InformationAboutProcessOfTheSequenzialTest' })}
                    expandIcon="fa fa-angle-down"
                    collapseIcon="fa fa-angle-up"
                    expanded={expanded}
                    onAction={(e) => setExpanded(!e.expanded)}
                    className="expansion-panel-counting-params"
                >
                    <Reveal>
                        {expanded && (
                            <ExpansionPanelContent>
                                <Row className="mt-2">
                                    <Col xs="9">
                                        <FormattedMessage id='CountListParameters.MinimumNumberOfItemsNeededToSeachAcceptanceThreshold' />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                            value={staseqCountingDefault.noOfItemsToReachTheAcceptanceThreshold}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="9">
                                        <FormattedMessage id='CountListParameters.AcceptanceThresholdAfterRecordingOfItems' />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                            value={staseqCountingDefault.acceptanceThresholdAfterFeedback}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="9">
                                        <FormattedMessage id='CountListParameters.RejectionThresholdAfterRecordingOfItems' />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                            value={staseqCountingDefault.rejectionThresholdAfterFeedback}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="9">
                                        <FormattedMessage id='CountListParameters.PresentNoOfErrors' />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                            value={staseqCountingDefault.currentNoOfErrors}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col xs="3">
                                    </Col>
                                    <Col xs="3">
                                        <FormattedMessage id='CountListParameters.Total' />
                                    </Col>
                                    <Col xs="3">
                                        <FormattedMessage id='CountListParameters.Counted' />
                                    </Col>
                                    <Col xs="3">
                                        <FormattedMessage id='CountListParameters.CountNow' />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="3">
                                        <FormattedMessage id='CountListParameters.ItemsCompleteCount' />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                            value={staseqCountingDefault.completeCount.totalItemsToCount}
                                        />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.completeCount.alreadyCountedItems"
                                            value={staseqCountingDefault.completeCount.alreadyCountedItems}
                                        />
                                    </Col>
                                    <Col xs="3">
                                        <NumericTextBox
                                            id="staseqCountingDefault.initalSample.itemsLeftToCount"
                                            value={completeCountItemsToCount}
                                            min={0}
                                            max={staseqMaxItemsToCount}
                                            disabled={!staseqCountingDefault.completeCountInputEnabled}
                                            onChange={handleCompleteCountItemsToCountChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs="3">
                                        <FormattedMessage id='CountListParameters.ItemsInitialSample' />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.initalSample.totalItemsToCount"
                                            value={staseqCountingDefault.initalSample.totalItemsToCount}
                                        />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.initalSample.alreadyCountedItems"
                                            value={staseqCountingDefault.initalSample.alreadyCountedItems}
                                        />
                                    </Col>
                                    <Col xs="3">
                                        <NumericTextBox
                                            id="staseqCountingDefault.initalSample.itemsLeftToCount"
                                            value={initalSampleItemsToCount}
                                            min={0}
                                            max={staseqMaxItemsToCount}
                                            disabled={!staseqCountingDefault.initalSampleInputEnabled}
                                            onChange={handleInitalSampleInputEnabledChange}
                                        />
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col xs="3">
                                        <FormattedMessage id='CountListParameters.ItemsSequentialTest' />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.sequentialTest.totalItemsToCount"
                                            value={staseqCountingDefault.sequentialTest.totalItemsToCount}
                                        />
                                    </Col>
                                    <Col xs="3">
                                        <Input
                                            disabled={true}
                                            id="staseqCountingDefault.sequentialTest.totalItemsToCount"
                                            value={staseqCountingDefault.sequentialTest.alreadyCountedItems}
                                        />
                                    </Col>
                                    <Col xs="3">
                                        <NumericTextBox
                                            id="staseqCountingDefault.sequentialTest.itemsLeftToCount"
                                            value={sequentialTestItemsToCount}
                                            min={0}
                                            max={staseqMaxItemsToCount}
                                            disabled={!staseqCountingDefault.sequentialTestInputEnabled}
                                            onChange={handleSequentialTestItemsToCountChange}
                                        />
                                    </Col>
                                </Row>
                            </ExpansionPanelContent>
                        )}
                            </Reveal>
                </ExpansionPanel>
            </CardBody>
        </Card>
        : <></>
    );
};

CountListParametersCardStaseq.propTypes = {
    isPerpetualInventory: PropTypes.bool,
    expandOnStart: PropTypes.bool,
    onCountingParametersValidityChanged: PropTypes.func,
    onSequentialTestItemsToCountChanged: PropTypes.func,
    onCompleteCountItemsToCountChanged: PropTypes.func,
    onInitalSampleItemsToCountChanged: PropTypes.func
};

export default CountListParametersCardStaseq;