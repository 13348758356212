import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { getInventoryMethod, loadPdfReport, setWaitingForErp } from '../../../../services/inventoryService';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useLoading } from '../../../Shared/LoadingContext';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { wizardEvents, wizardStep } from '../Wizard';
import { WizzardContext } from '../WizzardContext';
import { off, on, trigger } from '../../../../actions/events';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import PreviewPDF from '../../PreviewPDF';
import CountListParametersCardStaseq from './CountListParametersCardStaseq';
import CountListParametersCardStasam from './CountListParametersCardStasam';

const CountListParameters = ({ setContinueBtnDisabled, showImportUpdatePdf, isPerpetualInventory }) => {
    const pageId = 'pageCountListParameters';
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();

    const [countingParametersValid, setCountingParametersValid] = useState(false);

    const [fileInitialImport, setFileInitialImport] = React.useState(null);
    const [selected, setSelected] = React.useState(0);
    const wizzardContext = React.useContext(WizzardContext);
    const [inventoryMethod, setInventoryMethod] = useState(null);

    const isHighQuality = useCallback(() => {
        return inventoryMethod === window.enumInventoryMethod.Staseq;
    },[inventoryMethod]);

    const showException = error => dispatchExceptionDialog({
        pageId: 'pageInventoryWizard.StockData',
        message: error.detail || error
    });

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);
    const selectedPopulation = useSelector(state => state.dashboard.population);

    const handleError = useCallback((errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    },[]);

    React.useEffect(() => {
        incrementLoadingCount();
        getInventoryMethod(
            selectedPopulation.populationId,
            result => {
                decrementLoadingCount();
                if (result.succeeded) {
                    setInventoryMethod(result.data);
                }
            },
            handleError
        );
    }, []);

    const onCountingParametersValidChanged = useCallback((isValid) => {
        setCountingParametersValid(isValid);
        setContinueBtnDisabled?.(isValid);
    }, [setContinueBtnDisabled]);

    const setWaitingForErpAndContinue = useCallback(() => {
        if (wizzardContext.wizzardGlobal.InterfaceConfiguration?.connectedToErp) {
            setLoading(true);
            setWaitingForErp(
                selectedPopulation.populationId,
                true,
                _success => {
                    setLoading(false);
                    trigger(wizardEvents.stepFinalized, {});
                },
                handleError
            );
            return;
        }

        trigger(wizardEvents.stepFinalized, {});
    },[handleError, selectedPopulation.populationId, setLoading, wizzardContext.wizzardGlobal.InterfaceConfiguration?.connectedToErp]);

    const continueButtonClicked = useCallback((event) => {
        if (event.detail === wizardStep.CountListParameters && countingParametersValid) {
            setWaitingForErpAndContinue();
        }
    },[countingParametersValid, setWaitingForErpAndContinue]);

    useEffect(() => {
        on(wizardEvents.continueButtonClicked, continueButtonClicked);

        return () => {
            off(wizardEvents.continueButtonClicked, continueButtonClicked);
        }
    }, [continueButtonClicked]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const pdfPreviewErrorCallback = error => {
        setLoading(false);
        showException(error);
    }

    const loadPdfSuccess = pdf => {
        setFileInitialImport(pdf);
        setLoading(false);
    }

    const handleSelect = async e => {
        setSelected(e.selected);
        switch (e.selected) {
            case 0:

                break;
            case 1:
                if (!fileInitialImport) {
                    setLoading(true);
                    loadPdfReport(window.enumReportType.UpdateImportResult, loadPdfSuccess, pdfPreviewErrorCallback, selectedPopulation?.populationId);
                }
                break;
            default:
                break;
        }
    };

    return ((inventoryMethod !== null) &&
        <div>
            <Row>
                <Col xs="9">
                    <TabStrip selected={selected} onSelect={handleSelect}>
                        <TabStripTab title={<FormattedMessage id='CountListParameters.CountingParameters' />}>
                            {isHighQuality()
                                ? <CountListParametersCardStaseq onCountingParametersValidityChanged={onCountingParametersValidChanged} expandOnStart={true} />
                                : <CountListParametersCardStasam isPerpetualInventory={isPerpetualInventory?.() ?? false} onCountingParametersValidityChanged={onCountingParametersValidChanged} />
                            }
                        </TabStripTab>
                        {showImportUpdatePdf &&
                            <TabStripTab title={<FormattedMessage id='General.PdfView' />} >
                                <div>
                                    <PreviewPDF file={fileInitialImport}
                                        prevPageText='<'
                                        nextPageText='>'
                                        downloadFileText='Download document'
                                    />
                                </div>
                            </TabStripTab>}
                    </TabStrip>
                </Col>
                <Col xs="3" >
                    <Card className="card-chart" >
                        <CardBody >
                            <p><FormattedMessage id='CountListParameters.TheCountListWillBeGeneratedUsingTheParametersDisplayed' /></p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </div>
    );
};

CountListParameters.propTypes = {
    setContinueBtnDisabled: PropTypes.func,
    showImportUpdatePdf: PropTypes.bool,
    isPerpetualInventory: PropTypes.func
};

export default CountListParameters;